@import "variables";
.main-banner-section {
  .banner-image{
    position: relative;
    width: 100%;
    height: 100vh;
    //background-size: cover !important;
    //background-position: center top !important;
    //background-repeat: no-repeat !important;
    background-color: #010039;
    @media (max-width: $small-laptop) {
      height: 80vh;
    }
    @media (max-width: $large-phone) {
      height: 60vh;
    }
    //&.b1{
    //  background-image: url("../assets/images/Haystack-Meet-Needle-Introducing-Stella-b1.jpg");
    //}
    //&.b2{
    //  background-image: url("../assets/images/b2.png");
    //}
    //&.b3{
    //  background-image: url("../assets/images/b3.png");
    //}
    .banner-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .carousel-content {
      height: 100%;
      position: relative;
      z-index: 2;
      .unicorn{
        width: 58%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: left;
        align-self: center;
        z-index: 2;
        display: flex;
        .text-wrapper{
          color: #fff;
          font-family: $font;
          margin-top: 50px;
          h3 {
            letter-spacing: -0.32px;
            font-size: 4.5vw;
            font-weight: 600;

            span {
              font-size: 3.3vw;
              font-weight: 200;
            }
          }
          h2{
            font-size: 4.7vw;
            font-weight: 600;
            letter-spacing: -0.16px;
            text-align: center;

          }
          @media (max-width: $small-phone) {
            h3{
              font-size: 6.5vw;
            }
            h2{
              font-size: 6.7vw;
            }
          }
        }
      }
      .singularity{
        width: 100%;
        height: 92%;
        justify-content: center;
        align-items: flex-end;
        text-align: left;
        align-self: flex-end;
        z-index: 2;
        display: flex;
        @media (max-width: $small-tablet) {
          height: 82%;
        }
        .text-wrapper{
          color: #fff;
          font-family: $font;
          h3 {
            font-size: 4.5vw;
            font-weight: 300;
            letter-spacing: -0.32px;
            span {
              font-size: 3.2552083333333335vw;
              font-weight: 300;
            }
          }
          h2{
            font-size: 4.7vw;
            font-weight: 600;
            letter-spacing: -0.16px;
            text-align: center;
          }
          @media (max-width: $small-phone) {
            h3{
              font-size: 6.5vw;
            }
            h2{
              font-size: 6.7vw;
            }
          }
        }
      }
      .needle{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-self: center;
        z-index: 2;
        display: flex;
        .text-wrapper{
          color: #fff;
          font-family: $font;
          h3 {
            font-size: 4.5vw;
            font-weight: 300;
            letter-spacing: -0.32px;
          }
          h2{
            font-size: 4.7vw;
            font-weight: 600;
            letter-spacing: -0.16px;
          }
          @media (max-width: $small-phone) {
            h3{
              font-size: 6.5vw;
            }
            h2{
              font-size: 6.7vw;
            }
          }
        }
      }
    }
  }
  .carousel-indicators{
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    li{
      overflow:hidden;
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 7px;
      margin: 0 3px;
      border-radius: 4px;
      border: solid 1px #fff;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: width 0.5s;
      &.active{
        background-color: #fff;
        width: 37px;
      }
    }
  }

}

//Header styling
.header-section {
  height: 100px;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  &.white-bg {
    background-color: $charcoal-text;
     .dropdown-menu {
      background-color: $charcoal-text;
    }
  }
}
.top-navigation {
  position: relative;
  width: 100%;
  max-width: $inner-body-width;
  margin: 0 auto;
  .container-fluid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $small-tablet) {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
    position: absolute;
    top: 20px;
    z-index: 100;
    width: 100%;
    margin-bottom: 0;
  }
  .navbar-header {
    .navbar-toggle{
      border-color: $white;
      background: transparent;
      .icon-bar{
        background: $white;
      }
    }
    .main-logo-wrapper {
      .logo-img {
        width: 248px;
        height: 60px;
        @media (max-width: $small-laptop) {
          width: 180px;
        }
      }
    }
    @media (max-width: $small-tablet) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
    }
  }
  .menu-list {
    margin-top: 0px;
    @media (max-width: $small-laptop) {
      padding: 0;
    }
    @media (max-width: $small-tablet) {
      width: 100%;
      background-color: $charcoal-text;
    }
    .menu-list-ul {
      li{
        //margin-right: 8px;
        margin-right: 16px;
        a {
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color:  $white;
          border: 1px solid transparent;
          padding: 4px 15px;
          border-radius: 30px;
          background: transparent !important;
          transition: 0.2s all;
          &:hover {
            border-color:  $white !important;
            transition: 0.2s all;
            background-color: transparent;
            color:  $white !important;
          }
          @media (max-width: $small-laptop) {
            font-size: 12px;
            padding: 4px 9px;
          }
        }
        &.active {
          a{
            border-color: $white !important;
            transition: 0.2s all;
          }
        }
      }
      @media (max-width: $small-tablet) {
        width: 100%;
        margin: 8px 0;
        padding: 0 15px;
        li {
          a {
            text-align: center;
          }
        }
      }
    }
  }
}
.dropdown-menu {
  background-color: rgb(248 248 248 / 20%);
  li {
    margin-right: 0 !important;
    a {
      border: none !important;
    }
  }
}
.sub-pages-header{
  border-bottom: 1px solid $borderColor2;
  position: fixed;
  height: 100px;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  z-index: 100;
  @media (max-width: $small-tablet) {
    height: 84px;
  }
  .top-navigation {
    .navbar-default{
      position: relative;
      top: auto;
      z-index: 100;
      margin: 20px 0;
      @media (max-width: $small-tablet) {
        margin: 10px 0;
      }
      .navbar-toggle{
        border-color:$menuDark;
        .icon-bar{
          background: $menuDark;
        }
      }
    }
    .menu-list {
      background: #fff;
      .menu-list-ul {
        li{
          a {
            color: $menuDark;
            &:hover {
              border-color:  $menuDark !important;
              transition: 0.2s all;
              background-color: transparent;
              color:  $menuDark !important;
            }
          }
          &.active {
            a{
              border-color: $menuDark !important;
            }
          }
          &.dropdown {
            &:hover{
              .dropdown-menu{
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .dropdown-menu{
    background-color: $white;
    li {
      margin-right: 0 !important;
      a {
        border: none !important;
      }
    }
  }
}

.after-header-section {
  margin-top: 100px;
  @media (max-width: $small-tablet) {
    margin-top: 84px;
  }
}
