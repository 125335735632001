//main slider styling
@use "sass:math";

.fnc {

  // main variables, basically everything depends on $slidingAT
  $slidingAT: 900ms;
  $slidingDelay: math.div($slidingAT, 3) * 2;
  $maskAT: $slidingAT*0.7;
  $headingAT: $slidingAT * 1.5;
  $controlAT: math.div($slidingAT, 2);
  $easing: ease-in-out;
  $blendMode: luminosity;
  $font: 'Poppins', sans-serif;
  $contentLeft: 60%;
  $contentTop: 40%;

  $headingPadding: 30px;
  $headingFontSize: 100px;

  $maskTop: 15%;
  $maskRight: 100% - $contentLeft;
  $maskHeight: 67vh;
  $maskWidth: $maskHeight * 0.75;

  $black: #1F2833;

  /* you can add color names and their values here
  and then simply add classes like .m--blend-$colorName to .fnc-slide
  to apply specific color for mask blend mode */
  $bgColors: (
          dark: lighten(#242424, 20%),
          green: #42605E,
          red: lighten(#6A0A0D, 10%),
          blue: #2D7791
  );

  &-slider {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    height: 100vh;
    @media (max-width: $large-laptop) {
      height: 80vh;
    }
    @media (max-width: $large-tablet) {
      height: 70vh;
    }
    @media (max-width: $small-tablet) {
      height: 50vh;
    }
    @media (max-width: $small-phone) {
      height: 45vh;
    }
    *, *:before, *:after {
      box-sizing: border-box;
    }

    &__slides {
      position: relative;
      height: 100%;
      transition: transform $slidingAT $slidingDelay;
    }

    // iterate through color map and set colors for specific classes
    @each $colorTheme, $bgColor in $bgColors {
      .m--blend- {
        .fnc-slide__inner {
          background-color: lighten($bgColor, 20%);
        }

        .fnc-slide__mask-inner {
          background-color: $bgColor;
        }
      }

      .m--navbg- {
        background-color: $bgColor;
      }
    }
  }

  &-slide {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0,0,0);

    &.m--before-sliding {
      z-index: 2 !important;
      transform: translate3d(100%,0,0);
    }

    &.m--active-slide {
      z-index: 1;
      transition: transform $slidingAT $slidingDelay $easing;
      transform: translate3d(0,0,0);
    }

    &__inner {
      position: relative;
      height: 100%;
      background-size: cover;
      background-position: center top;
      transform: translate3d(0,0,0);

      .m--global-blending-active &,
      .m--blend-bg-active & {
        background-blend-mode: $blendMode;
      }

      .m--before-sliding & {
        transform: translate3d(-100%,0,0);
      }

      .m--active-slide & {
        transition: transform $slidingAT $slidingDelay $easing;
        transform: translate3d(0,0,0);
      }
    }


    &__content {
      z-index: 2;
      display: flex;
      &.unicorn{
        width: 58%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: left;
        align-self: center;
        .text-wrapper{
          color: #fff;
          font-family: $font;
          margin-top: 50px;
          h3 {
            letter-spacing: -0.32px;
            font-size: 5vw;
            font-weight: 600;

            span {
              font-size: 3.3vw;
              font-weight: 200;
            }
          }
          h2{
            font-size: 6.5vw;
            font-weight: 600;
            letter-spacing: -0.16px;
            text-align: center;

          }
          @media (max-width: $small-phone) {
            h3{
              font-size: 6.5vw;
            }
            h2{
              font-size: 6.7vw;
            }
          }
        }
      }
      &.singularity{
        width: 100%;
        height: 92%;
        justify-content: center;
        align-items: flex-end;
        text-align: left;
        align-self: flex-end;
        @media (max-width: $small-tablet) {
          height: 82%;
        }
        .text-wrapper{
          color: #fff;
          font-family: $font;
          h3 {
            font-size: 4.7vw;
            font-weight: 600;
            letter-spacing: -0.32px;
            span {
              font-size: 3.2552083333333335vw;
              font-weight: 300;
            }
          }
          h2{
            font-size: 4.5vw;
            font-weight: 600;
            letter-spacing: -0.16px;
            text-align: center;
          }
          @media (max-width: $small-phone) {
            h3{
              font-size: 6.5vw;
            }
            h2{
              font-size: 6.7vw;
            }
          }
        }
      }
      &.needle{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-self: center;
        .text-wrapper{
          color: #fff;
          font-family: $font;
          h3 {
            font-size: 4.5vw;
            font-weight: 300;
            letter-spacing: -0.32px;
          }
          h2{
            font-size: 4.7vw;
            font-weight: 600;
            letter-spacing: -0.16px;
          }
          @media (max-width: $small-phone) {
            h3{
              font-size: 6.5vw;
            }
            h2{
              font-size: 6.7vw;
            }
          }
        }
      }
    }

    &__heading {
      //margin-bottom: 10px;
      &-line {
        &:nth-child(2) {
          //padding-left: $headingPadding;
          font-weight: 500;
        }

        .m--before-sliding & {
          transform: translateY(100%);
        }

        .m--active-slide & {
          transition: transform $headingAT $slidingDelay*1.5;
          transform: translateY(0);
        }

        .m--previous-slide & {
          transition: transform $headingAT;
          transform: translateY(-100%);
        }

        span {
          //display: block;
          .m--before-sliding & {
            transform: translateY(-100%);
          }

          .m--active-slide & {
            transition: transform $headingAT $slidingDelay*1.5;
            transform: translateY(0);
          }

          .m--previous-slide & {
            transition: transform $headingAT;
            transform: translateY(100%);
          }
        }
      }
    }

  }

  &-nav {
    z-index: 5;
    position: absolute;
    left: 50%;
    bottom: 30px;
    margin-left: -48px;
    &__bgs {
      z-index: -1;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      &.m--nav-bg-before {
        z-index: 2 !important;
        transform: translateX(100%);
      }

      &.m--active-nav-bg {
        z-index: 1;
        transition: transform $slidingAT $slidingDelay;
        transform: translateX(0);
      }
    }

    &__controls {
      font-size: 0;
    }

    &__control {
      overflow: hidden;
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 7px;
      margin: 0 3px;
      border-radius: 4px;
      border: solid 1px #fff;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: width $controlAT;

      &.m--active-control {
        background-color: #fff;
        width: 37px;
      }

      &-progress {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #fff;
        transform-origin: 0 50%;
        transform: scaleX(0);
        transition-timing-function: linear !important;

        .m--with-autosliding .m--active-control & {
          transform: scaleX(1);
        }

        .m--prev-control & {
          transform: translateX(100%);
          transition: transform 0.5s !important;
        }

        .m--reset-progress & {
          transform: scaleX(0);
          transition: transform 0s 0s !important;
        }

        .m--autosliding-blocked & {
          transition: all 0s 0s !important;
          transform: scaleX(0) !important;
        }
      }
    }
  }

}


/* NOT PART OF COMMON SLIDER STYLES */
$font: 'Poppins', sans-serif;
$creditsAT: 0.7s;
$creditsWidth: 400px;

body {
  margin: 0;
}

.demo-cont {
  overflow: hidden;
  position: relative;
  height: 100vh;
  perspective: 1500px;
  background: #000;
  @media (max-width: $large-laptop) {
    height: 80vh;
  }
  @media (max-width: $large-tablet) {
    height: 70vh;
  }
  @media (max-width: $small-tablet) {
    height: 50vh;
  }
  @media (max-width: $small-phone) {
    height: 45vh;
  }
}

.example-slider {
  z-index: 2;
  transform: translate3d(0,0,0);
  transition: transform $creditsAT;

  .credits-active & {
    transform: translate3d($creditsWidth*-1,0,0) rotateY(10deg) scale(0.9);
  }

  .fnc-slide {
    .fnc-slide__inner {
      .slide-inner-img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .fnc-slide__heading,
  .fnc-slide__action-btn,
  .fnc-nav__control {
    font-family: $font;
  }
}

