.stella-career-section {
  padding: 80px 0px;
  &:after {
    content: "";
    position: absolute;
    top: 160px;
    left: -140px;
    transform: rotate(25deg);
    opacity: 0.2;
    width: 260px;
    height: 158px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/title-balls.png');
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
}
.stella-career-about-section{
  padding: 80px 0px 0 0;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
}

//------------------------------------------------------------------

.career-wrapper {
  //background: #40c4c7;
  margin-bottom: 46px;
  .career-container{
    width: 1505px;
    max-width: 100%;
  }
  .career-header{
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $small-tablet) {
      flex-direction: column-reverse;
    }
    .career-title{
      display: flex;
      width: calc(100% - 350px);
      background: #40c4c7;
      gap: 15px;
      padding: 30px;
      border-radius: 12px 0 0 12px;
      @media (max-width: $small-tablet) {
        width: 100%;
        border-radius: 12px;
      }
      .career-slider-wrapper {
        width: 4px;
        height: 254px;
        background-color: rgb(255 255 255 / 35%);
        border-radius: 5px;
        position: relative;
        .slideBar {
          width: 4px;
          height: 20%;
          display: block;
          border-radius: 5px;
          cursor: pointer;
          position: relative;
          top: 0;
          transition: 0.5s ease-in;
          background-color: #fff;
        }
      }
      .career-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .career-inner-content {
          display: flex;
          flex-direction: column;
          gap: 16px;
          h3 {
            color: #FFFFFF !important;
            font-size: 30px;
            font-weight: 600;
            span{
              font-weight: 300;
            }
          }
          .content-text{
            height: 180px;
            overflow: overlay;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none !important;
              background-color: transparent;
              border-radius: 6px;
            }
            &::-webkit-scrollbar {
              width: 4px;
              background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 6px;
              background-image: $gradient;
            }
            p{
              color: #FFFFFF !important;
              font-size: 18px;
              font-weight: 300;
            }
          }
        }
        .career-images-wrapper {
          display: flex;
          margin-left: 10px;
          align-items: center;
          li {
            margin-left: -10px;
            .image-box {
              display: flex;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              overflow: hidden;
              border: 2px solid #40c4c7;
              transition: 0.5s all;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              &.active, &:hover {
                width: 42px;
                height: 42px;
                transition: 0.5s all;
                position: relative;
                z-index: 2;
              }
            }
          }
        }
      }

    }
    .career-image{
      width: 350px;
      height: 400px;
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      cursor: pointer;
      @media (max-width: $small-tablet) {
        width: calc(100% - 100px);
        height: 400px;
        border-radius: 12px 12px 0px 0px;
      }
     &:after {
       content: "";
       position: absolute;
       left: 12px;
       top: 12px;
       width: calc(100% - 24px);
       height: calc(100% - 24px);
       border: 1px solid #fff;
       border-radius: 12px;
     }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s all;
      }
      &:hover{
        img{
          transform: scale(1.3);
          transition: 0.7s all;
        }
      }
    }
  }
}
.career-value-section {
  padding: 0 0 80px 0 !important;
}

//-----------------------------------------------------------------------------

.value-section {
  &:after {
    transform: rotate(40deg) !important;
    opacity: 0.2 !important;
    right: -110px !important;
  }
}

//------------------------------------------------------------------------------

.benefit-wrapper {
  background: #f5f7fa;
  &:after {
    content: "";
    position: absolute;
    margin-top: 78px;
    right: -100px;
    transform: rotate(60deg);
    opacity: 20%;
    width: 260px;
    height: 158px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/images/title-balls.png");
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .ben-wrap{
    width: 80%;
    @media (max-width: $small-tablet) {
      width: 100%;
    }
  }
  .benefit-section-title {
    padding: 60px 0 0 0;
    text-align: center;
    max-width: 100%;
    margin: 0 auto 50px;
    h1{
      max-width: 75%;
      color: #000;
      margin: 0 auto 24px;
      font-size: 4.5rem;
      font-weight: 600;
      @media(max-width:768px) {
        max-width: 100%;
      }
    }
  }
  .benefit-header{
    margin: 0;
    gap: 40px;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    @media (max-width: $small-tablet) {
     flex-direction: column;
    }

    h3 {
      font-size: 36px;
      font-weight: 600;
      color: black;
    }
    .benefit-title{
      display: flex;
      flex-direction: column;
      flex-basis: 55%;
      //justify-content: space-between;
      .benefit-detail{
        border-top: 2px dotted #11adb3;
        padding-top: 45px;
        max-width: 600px;
        margin-top: 38px;
        //margin-left: 60px;
        @media (max-width: $small-tablet) {
          margin-left: 0px;
          padding-top: 40px;
        }
        p{
          font-size: 20px;
          padding-bottom: 45px;
        }
      }
      .benefit-bullets{
        display: flex;
        //align-items: center;
        flex-direction: row;
        padding-bottom: 14px;
        gap: 36px;
        @media (max-width: $small-tablet) {
          padding-bottom: 40px;
          flex-direction: column;
        }
        h3{
          font-size: 18px;
          font-weight: 400;
        }
        .bullet{
          display: flex;
          align-items: center;
          gap: 10px;
          text-wrap: wrap;
          .bullet-logo{
            width: 25px;
            height: 25px;
            flex: 0 0 25px;
          }
        }
        .bullet21, .bullet22{
          .bullet-logo{
            width: 18px;
            margin-right: 7px;
          }
        }

        .benefits-bullets-left {
          //flex-basis: 30%;
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
        .benefits-bullets-right{
          //flex-basis: 70%;
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }
    }
    .benefit-images{
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-basis: 45%;
      //height: 390px;
      position: relative;
      .image5{
        width: 335px;
        height: 246px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: $small-tablet) {
          width: 50%;
        }
      }
      .benefit-image-wrapper{
        border: 2px solid #fff;
        border-radius: 25px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 1s;
        }
        &:hover{
          img {
            transform: scale(1.2);
            transition: all 1s;
          }
        }
      }
      .benefit-images-left {
        gap: 20px;
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        .benefit-image1 {
          width: 420px;
          height: 285px;
          @media (max-width: $small-tablet) {
            width: 50%;
          }
        }
        .benefit-image2 {
          width: 310px;
          height: 380px;
          @media (max-width: $small-tablet) {
            width: 50%;
          }
        }


      }
      .benefit-images-right {
        gap: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        .benefit-image3 {
          width: 285px;
          height: 191px;
          @media (max-width: $small-tablet) {
            width: 50%;
          }
        }

        .benefit-image4 {
          width: 431px;
          height: 287px;
          @media (max-width: $small-tablet) {
            width: 50%;
          }
        }
      }

    }
  }
}

//-----------------------------------------------------------------------------

  .testimonials-wrapper {
    //background: $pale-grey-bg;
    padding: 80px 0px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: calc(100% - 55%);
      left: -15px;
      width: 206px;
      height: 47px;
      background-image: url('../assets/images/left-coming-object.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @media (max-width: $small-tablet) {
        display: none;
      }
    }
    @media (max-width: $small-tablet) {
      padding: 50px 0;
    }
  .trusted-client-inner {
    .drag-icon {
      width: 50px;
      position: absolute;
      right: -45px;
      top: 130px;
      opacity: 0.4;
      @media (max-width: $large-laptop) {
        right: -5px;
      }
      @media (max-width: $medium-laptop) {
        display: none;
      }
    }
  }
    .testimonial-inner{
      .owl-nav{
        .owl-prev, .owl-next{
          border: none !important;
          font-size: 16px !important;
          &:before{
            color: #dedfe2 !important;
          }
          &:hover{
            background-color: transparent!important;
            &:before{
              color: #8e8e8e !important;
            }
          }
        }
      }
    }
}
  .grey-border{
  border: 1px solid #b7b7b7;
  //height: 230px;
  width: 400px;
  border-radius: 10px;
  justify-content: flex-start;
  padding: 16px;
  display: flex;
    @media (max-width: $small-tablet) {
      width: 100%;
    }
  .testimonial-data{
    display: flex;
    flex-direction: column;
    gap: 18px;
    .avatar-wrapper-head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      .avatar-left {
        background-color: #e2fbfb;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
      }
      .avatar-right-title {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 6px;
        h3{
          font-size: 18px;
          font-weight: 600;
          color: #29bdc7;
        }
        h5{
          font-size: 16px;
          font-weight: 500;
          color: #8e8e8e;
        }
      }
    }
    p{
      font-size: 12px;
      color: #8d8b8b;
      text-align: left;
    }
  }
}

//---------------------------------------------------------------------------

.event-wrapper {
  background: $pale-grey-bg;
  padding: 80px 0px;
  position: relative;
  .events-item{
    //max-width: 1440px;
    //height: 480px;
    //border-radius: 25px;
    //background-image: url("../assets/images/Screenshot_1.png");
    //background-size: cover !important;
    //background-position: center center !important;
    //background-repeat: no-repeat !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    #stellavideo{
      max-width: 100%;
      height: auto;
    }
    .playbtn{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      box-shadow: none;
      border: none;
      outline: none;
      opacity: 0;
      transition: 0.2s ease-in;
      &:hover{
        transform: translate(-50%, -50%) scale(1.2);
        cursor: pointer;
        transition: 0.5s;
      }
    }
    &:hover{
      .playbtn{
        opacity: 1;
        transition: 0.2s ease-in;
      }
    }
  }
}

//----------------------------------------------------------------------------

.gallery-wrapper {
  padding: 80px 0px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 550px;
    right: -100px;
    transform: rotate(60deg);
    opacity: 20%;
    width: 260px;
    height: 158px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/images/title-balls.png");
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .gallery-background-image{
    .image-wrapper{
      position: absolute;
      right: 80%;
      object-fit: fill;
      width: 60%;
      height: 100%;
      opacity: 10%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .gallery {
    .gallery-items{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 28px;
      @media (max-width: $small-tablet) {
        flex-direction: column;
        gap: 20px;
      }
      .gallery-image-wrapper{
        border: 2px solid #fff;
        border-radius: 25px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 1s;
          @media (max-width: $small-tablet) {
            width: 100% !important;
          }
        }
        &:hover{
          img {
            transform: scale(1.2);
            transition: all 1s;
          }
          .gallery-image-content {
            bottom: 0;
            transition: 0.5s;
          }
        }
        .gallery-image-content {
          position: absolute;
          bottom: -100%;
          border: 1px solid #fff;
          border-radius: 8px 8px 0 0;
          width: 90%;
          margin: 0 auto;
          left: 0;
          right: 0;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 10px;
          gap: 10px;
          display: flex;
          transition: 0.5s;
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 46%);
            top: 0;
            left: 0;
            right: 0;
          }
          p {
            color: #fff;
            position: relative;
            z-index: 2;
            text-align: center;
            font-size: 12px;
          }
          .gallery-icons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            position: relative;
            z-index: 2;
            a {
              color: #fff;
              font-size: 18px;
            }
          }
        }
      }
      .gallery-item-left{
        flex-basis: 50%;
        display: flex;
        gap: 28px;
        @media (max-width: $small-tablet) {
          width: 100%;
          gap: 20px;
        }
        .gal-item-left-top{
          display: flex;
          flex-direction: column;
          gap: 28px;
          @media (max-width: $small-tablet) {
            width: 50%;
            gap: 20px;
          }
          img{
            width: 344px;
            height: 222px;
            //border-radius: 10px;
          }
        }
        .gal-item-left-down{
          @media (max-width: $small-tablet) {
            width: 50%;
          }
          img{
            width: 344px;
            height: 472px;
            //border-radius: 10px;
          }
        }
      }
      .gallery-item-right{
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        gap: 28px;
        @media (max-width: $small-tablet) {
          width: 100%;
          gap: 20px;
        }
        .gal-item-right-top{
          img{
            width: 716px;
            height: 222px;
            //border-radius: 10px;
          }
        }
        .gal-item-right-down{
          display: flex;
          flex-direction: row;
          gap: 28px;
          @media (max-width: $small-tablet) {
            gap: 20px;
          }
          img{
            width: 344px;
            height: 222px;
            //border-radius: 10px;
          }
        }
      }
    }
  }
}

//---------------------------------------------------------------------

.upcoming-wrapper {
  background: $pale-grey-bg;
  padding: 80px 0px;
  position: relative;
  .upcoming-content {
    display: flex;
    align-items: stretch;
    background-color: #fff;
    @media (max-width: $small-tablet) {
      flex-direction: column;
    }
    .nav {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 0;
      @media (max-width: $small-tablet) {
        flex-direction: row;
      }
      &.nav-pills{
        li{
          @media (max-width: $small-tablet) {
            width: 12%;
            transition: 0.5s width;
            &.active {
              width: 88%;
              transition: 0.5s width;
            }
          }
          a {
            display: flex;
            gap: 20px;
            border-radius: 0;
            height: 200px;
            background-color: #29bdc7;
            transition: 0.5s ease;
            padding: 10px;
            overflow: hidden;
            &:hover {
              background-color: #163549;
              transition: 0.5s ease;
            }
            .year-wrapper{
              width: 20px;
              h3 {
                font-family: "Bebas Neue", sans-serif;
                -webkit-text-stroke: 0.5px #FFFFFF;
                color: transparent;
                font-size: 42px;
                word-wrap: break-word;
              }
            }
            .date-wrapper {
              display: flex;
              flex-direction: column;
              gap: 10px;
              h2 {
                font-size: 30px;
                color: #fff;
                font-weight: 500;
              }
              p {
                color: #fff;
                font-size: 20px;
              }
            }
          }
          &.active {
            a{
              background-color: #163549;
              transition: 0.5s ease;
              &:focus, &:hover{
                background-color: #163549;
                transition: 0.5s ease;
              }
            }
          }
        }
      }
    }
    .tab-content {
      background-color: #fff;
      padding: 0;
      .monthly-content-wrapper{
        display: flex;
        flex-direction: column;
        height: 600px;
        overflow: overlay;
        padding: 0px 30px;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none !important;
          background-color: transparent;
          border-radius: 6px;
        }
        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-image: $gradient;
        }
        .monthly-content{
          display: flex;
          gap: 20px;
          padding: 30px 0px;
          border-bottom: 1px solid #e3e3e3;
          &:last-child{
            border-bottom: none;
          }
          @media (max-width: $small-tablet) {
            flex-direction: column;
            gap: 10px;
          }
          .date-content {
            width: 40%;
            display: flex;
            gap: 10px;
            @media (max-width: $small-tablet) {
              width: 100%;
            }
            h3 {
              font-size: 16px;
              color: #29bdc7;
              font-weight: 400;
            }
          }
          .data-content {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            @media (max-width: $small-tablet) {
              width: 100%;
            }
            h2 {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}


//---------------------------------------------------------------------------

.our-jobs-wrapper{
  padding: 80px 0px;
  position: relative;
  background-image: url("../assets/images/OurJobs-ImageDark.jpg");
  background-size: 65%;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-color: #f6f7fb;
  .jobs-background-image{
    .image-wrapper{
      position: absolute;
      right: 80%;
      opacity: 10%;
      width: 180px;
      transform: rotateZ(315deg);
      left: 100px;
      top: 25%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .ourjobs-title{
    text-align: left !important;
    margin: 0 0 50px !important;
    button{
      width: 204px;
      height: 38px;
      text-align: center;
      padding: 0;
      margin-top: 32px;
    }
  }
}


