.r-lines{
  top: 620px !important;
}

.stella-resources-section {
  padding: 80px 0px;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .resources-background-image{
    //position: relative;
    //width: 50%;
    //height: 100vh;
    .image-wrapper{

      position: absolute;
      right: -20%;
      top: -184px;
      //z-index:999;
      width: 55%;
      opacity: 30%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .section-title {

    margin-bottom: 0 !important;
    p{
      margin-bottom: 20px;
      line-height: 30px;
    }
    #hidden-text {
      display: none;
    }
    .searchbar {
      display: inline-flex;
      position: relative;
      .input-field {
        width: 430px;
        height: 44px;
        text-align: left;
        box-shadow: none;
        border: 1px solid #8f8f8f;
        border-radius: 40px;
        outline: none;
        padding: 0 50px 0 20px;
        @media (max-width:480px){
          width: 300px;
        }
      }
      .icon-wrapper, .loading-spinner{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        color: #bdbcbc;
      }
    }

  }
  .primary-button {
    margin-top: 20px;
    &.gradient-btn{
      border: 1px solid transparent;
      background-image: linear-gradient(100deg,$topaz 0%, $bright-cyan 100%);
      color: #fff;
    }
  }
}


.resources-section {
  padding: 80px 0px;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
&.resources-section-detail{
  .resources-wrapper {
    .tab-content {
      margin-top: 0px;
    }
  }

}
  .section-title {
    margin-bottom: 0;

    p {
      margin-bottom: 20px;
    }

    #hidden-text {
      display: none;
    }
  }

  .resources-wrapper {
    ul {
      &.nav {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $small-tablet) {
          flex-direction: column;
          gap: 20px;
        }
        li {
          padding: 0 32px;
          border-right: 1px solid #979797;
          line-height: 1;
          float: none;
          margin-left: 0;
            @media (max-width: $small-tablet) {
              border-right: none;
            }
          a {
            color: #979797;
            font-size: 34px;
            font-weight: 500;
            padding: 0;

            &:hover, &:focus {
              background-color: transparent;
              color: #000;
            }
          }

          &:last-child {
            border-right: none;
          }

          &.active {
            a {
              background-color: transparent;
              color: #000;
              &:hover, &:focus {
                background-color: transparent;
                color: #000;
              }
            }
          }
        }
      }
    }
    .tab-content{
      margin-top: 40px;
      .view-more-btn {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
      .resource-item-list {
        justify-content: flex-start;
        font-family: Poppins, sans-serif;
        display: flex;
        gap: 40px 40px;
        flex-wrap: wrap;
        @media (max-width: $small-tablet) {
          flex-direction: column;
          gap: 50px;
        }
        .resource-item {
          width: calc(33.33% - 26.633px);
          display: flex;
          flex-direction: column;
          @media (max-width: $small-tablet) {
            width: 100%;
          }
          .resource-content{
            cursor: pointer;
            transition: 0.5s all;
            overflow: hidden;
            position: relative;
            .resource-image{
              width: 100%;
              height: 200px;
              @media (max-width: $small-tablet) {
                height: 300px;
              }
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.5s all;
              }
            }
            .resource-text{
              background-color: #fff;
              padding: 16px;
              padding-bottom: 0!important;
              h5{
                font-size: 14px;
                color: #000;
                text-align: left;
                margin: 12px 0;
              }
              h2{
                font-size: 22px;
                font-weight: 600;
                text-align: left;
                color: #000;
                margin: 0 0 16px;
                height: 50px;
                overflow: hidden;
              }
              p{
                letter-spacing: -.08px;
                text-align: left;
                color: #5b5b5b;
                font-size: 16px;
                font-weight: 300;
                line-height: 1.38;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                //margin-bottom: 8px;
              }
              ul{
                list-style: disc;
                padding-left: 20px;
                li{
                  &::marker{
                    color: #0fb4ba;
                  }
                }
              }
              .resource-actions{
                &.tag-action{
                  justify-content: flex-start;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                //padding-top: 8px;
                //padding-bottom: 10px;
                h4{
                  font-size: 14px;
                  color: #000;
                  padding-top: 8px;
                  padding-bottom: 10px;
                }
                i.fa-solid.fa-download {
                  border: 1px transparent;
                  padding-left: 4px;
                  padding-right: 4px;
                  border-radius: 25%;
                  &:hover{
                    border: 1px solid;
                    padding: 3px;
                    border-radius: 25%;
                    //transition: 0.1s;
                  }
                  &:focus{
                    color: $aqua3;
                  }
                }
              }
            }
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0;
              height: 3px;
              background-color: #22B0BA;
              transition: 0.5s all;
            }
            &:hover{
              box-shadow: 8px 8px 24px -10px rgba(0,0,0,0.30);
              box-sizing: border-box;
              transition: 0.1s;
              .resource-image{
                img{
                  transition: 0.5s all;
                  //transform: scale(1.02);
                }
              }
              &:after {
                width: 100%;
                transition: 0.5s all;
              }
            }
          }
        }
      }
    }
  }
}
