//Our Trusted Clients testimonials styling
@import "variables";

.owl-carousel {
  .owl-item {
    transition: all 0.3s ease-in-out;
    //min-height: 485px;
    .card {
      padding: 30px;
      position: relative;
    }
  }

  .owl-stage-outer {
    overflow-y: auto !important;
    .owl-stage{
      display: flex;
      align-items: center;
    }
  }

  .owl-item {
    //width: 470px !important;
    text-align: center;
    .card-img {
      width: 65px;
      height: 65px;
      border-radius: 6px;
      margin:-35px auto 16px;
    }

    .card {
      padding: 2px;
      opacity: 0.6;
      transform: scale3d(0.8, 0.8, 0.8);
      transition: all 0.3s ease-in-out;
      font-family: "Poppins", sans-serif;
      .gradient-border {
        padding: 0;
        .gradient-border-inner {
          padding: 16px 16px 40px;
          p.testimonial {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.64;
            letter-spacing: -0.07px;
            text-align: center;
            color: #000;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }
        .avatar-wrapper {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -30px;
          margin: 0 auto;
          width: 66px;
          height: 66px;
          border-radius: 50%;
          background: linear-gradient(to top, #78dfdf, #b2a3d2, #78dfdf, #b2a3d2) padding-box padding-box;
          display: flex;
          justify-content: center;
          align-items: center;
          .avatar{
            width: 62px;
            height: 62px;
            background-color: $pale-grey-bg;
            border-radius: 50px;
            background-size: cover !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            &.avatar1 {
             background-image: url("../assets/images/qs-client.jpg");
            }
            &.avatar2 {
              background-image: url("../assets/images/luis.png");
            }
            &.avatar3 {
              background-image: url("../assets/images/jon-read.png");
            }
          }
        }
      }
      .light-blue-bg {
        background-color: rgb(118,198,204, 0);
        border-radius: 0 0 30px 30px;
        padding-top: 40px;
        width: 300px;
        margin: 0 auto 0;
        padding-bottom: 10px;
      }
      .author-name {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #000;
        margin: 0 0 8px;
      }
      .author-role {
        font-family: Poppins;
        font-size: 14px;
        text-align: center;
        color: $aqua;
        margin: 0 0 3px;
      }
      .author-company {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #949595;
        font-style: italic;
      }
    }

    &.active.center .card {
      opacity: 1;
      transform: scale3d(1, 1, 1);
      .gradient-border {
        padding: 1px;
        .gradient-border-inner {
          p.testimonial {
            display: initial;
            overflow: initial;
            -webkit-line-clamp: initial;
            -webkit-box-orient: initial;
            margin: 10px 0;
            font-weight: 500;
          }
        }
        .avatar-wrapper {
          .avatar{
            background-color: $white;
          }
        }
      }
      .light-blue-bg {
        background-color: $seafoam-blue07;
      }
    }
  }

  .owl-nav{
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    .owl-prev, .owl-next{
      margin: 0;
      padding: 0;
      width: 50px;
      height: 50px;
      color: transparent;
      //border: 1px solid $aqua3;
      background-color: transparent;
      border-radius: 10px;
      font-size: 18px;
      position: relative;
      overflow: hidden;
      transition: 0.2s all;
      &:before{
        //color: $aqua3;
        color: #dedfe2 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: var(--fa-style-family,"Font Awesome 6 Free");
        font-weight: var(--fa-style,900);
      }
      &:hover{
        color: transparent;
        //background-color: $aqua3;
        background-color: transparent;
        &:before{
          //color: $white;
          color: #8e8e8e !important;
        }
      }
    }
    .owl-prev{
      &:before{
        content: "\f053";
      }
    }
    .owl-next{
      &:before{
        content: "\f054";

      }
    }
  }
  .owl-dots {
    //display: inline-block;
    //width: 100%;
    //text-align: center;
    display: none;
  }
}

.owl-theme .owl-dots .owl-dot {
  span {
    height: 20px;
    background: #2a6ba3 !important;
    border-radius: 2px !important;
    opacity: 0.8;
  }

  &.active span, &:hover span {
    height: 13px;
    width: 13px;
    opacity: 1;
    transform: translateY(2px);
    background: #83b8e7 !important;
  }
}

@media (min-width: 480.6px) and (max-width: 575.5px) {
  .owl-carousel .owl-item .card .name {
    left: 24%;
  }
}

@media (max-width: 360px) {
  .owl-carousel .owl-item .card .name {
    left: 30%;
  }
}
