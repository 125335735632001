//colors
$light-text: #9d9d9d;
$item-shadow: rgba(41, 41, 41, 0.22);
$pale-grey-bg: #f5f7fa;
$blue-gradient-border: rgba(67, 152, 161, 0.31);
$aqua: #1ae5cd;
$aqua2: #0fb5bc;
$aqua3: #06aeb8;
$inactive-slider: #c2c1c1;
$grey-text: #bebebe;
$charcoal-text: #343741;
$topaz: #0eada4;
$bright-cyan: #5ee7df;
$pale-purple: #b2a3d2;
$tiffany-blue: #78dfdf;
$teal-shadow: rgba(2, 59, 59, 0.22);
$teal-box-shadow: 0px 4px 6px 0.2px $teal-shadow;
$seafoam-blue: #76c6cc;
$seafoam-blue07: rgb(118,198,204, 0.07);
$white: #ffffff;
$white2: #fafafa;
$white3:#f4f4f4;
$borderColor: #a2a2a2;
$borderColor2: #b3b0b0;
$borderColor3: #717171;
$borderColor4: #31bcc3;
$borderColor5: #06aeb8;
$footerText: #ebebeb;
$ceoTitle: #010101;
$textBlack: #000000;
$quoteIcon :#b0b0b0;
$quoteIcon2 :#e5e5e5;
$quoteIcon3 :#dadbdd;
$squareBg: #48a26d;
$menuDark: #4c4d4f;
$topaz: #11adb3;
$topaz2: rgba(15,180,186, 0.7);
$twitterColor: #03a9f5;
$squareBox: rgb(15 180 186 / 70%);
$gradient: linear-gradient(100deg, #0eada4 0%, #5ee7df 100%);
$blue-grey: #8691a1;
$light-peach: #d1cfcf;
//widths
$max-width: 1920px;
$inner-body-width: 1170px;

//Media screens
$large-laptop: 1280px;
$medium-laptop: 1200px;
$small-laptop: 1024px;
$large-tablet: 992px;
$small-tablet: 768px;
$large-phone: 680px;
$phablet: 600px;
$small-phone: 550px;
$iphone-pro-max: 414px;
$iphone-pro: 390px;
$iphone-mini: 360px;
$smallest-phone: 320px;
