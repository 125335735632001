@import "variables";

.main-footer-wrapper{
  .main-footer-inner{
    background-color: $charcoal-text;
    padding: 55px 0;
    @media (max-width: $small-tablet) {
      padding: 50px 0;
    }
      .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media (max-width: $small-tablet) {
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 50px;
        }
        &:before{
          @media (max-width: $small-tablet) {
            display: none;
          }
        }
      .footer-col-left {
        .logo {
          width: 155px;
          margin-bottom: 40px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        .page-links {
          padding-left: 40px;
          @media (max-width: $small-tablet) {
            padding-left: 0px;
          }
          li {
            margin-bottom: 15px;
            a {
              color: $footerText;
              font-size: 14px;
              border-width: 0 0 1px 0;
              border-style: solid;
              border-color: transparent;
              transition: 0.2s ease-in;
              &:hover{
                text-decoration: none;
                border-color: $footerText;
                transition: 0.2s ease-in;
              }
            }
            &:last-child{
              margin-bottom: 0;
            }
            &.dropdown{
              .dropdown-menu{
                background-color: $charcoal-text;
                li{
                  margin-bottom: 0px;
                  a{
                    font-size: 13px;
                    color: $footerText;
                    text-decoration: none;
                    border-bottom: 1px solid $borderColor;
                    margin-bottom: 5px;
                    padding-bottom: 5px;
                    display: inline-flex;
                    transition: 0.2s all;
                    &:hover{
                      color: $white;
                      background-color: transparent;
                    }
                  }
                  &:last-child{
                    a{
                      margin-bottom: 0;
                      padding-bottom: 0;
                      border-bottom: none;
                    }
                  }
                }
              }
              &:hover{
                  .dropdown-menu{
                    display: block;
                  }
              }
            }
            &.active{
              a{
                text-decoration: none;
                border-color: $footerText;
                transition: 0.2s ease-in;
              }
            }
          }
        }
      }
      .footer-col-mid {
        .col-title{
          margin-bottom: 35px;
          h3 {
            font-size: 18px;
            color: $footerText;
            font-weight: 500;
            margin: 0;
            //border-bottom: 1px solid $topaz;
            display: inline-flex;
          }
        }
        .offices-list{
          li{
            margin-bottom: 20px;
            h4{
              font-size: 16px;
              color: $topaz;
              font-weight: 500;
              margin: 0 0 10px;
            }
            p{
              font-size: 14px;
              color: $footerText;
              margin: 0;
            }
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      .footer-col-right {
        .contact-info{
          p{
            font-size: 14px;
            color: $footerText;
            margin-bottom: 12px;
          }
          a{
            font-size: 14px;
            color: $footerText;
            text-decoration: none;
            border-bottom: 1px solid $borderColor;
            margin-bottom: 12px;
            padding-bottom: 8px;
            display: inline-flex;
            transition: 0.2s all;
            &:hover{
              color: $white;
            }
          }
        }
        .social-media-list{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 25px;
          li{
            a{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              border-radius: 8px;
              border: 1px solid $topaz;
              background-color: $topaz;
              color: #343741;
              transition: 0.2s ease-in;
              &:hover {
                background-color: transparent;
                color: $topaz;
                transition: 0.2s ease-in;
              }
            }
          }
        }
      }
      }
  }
}
.footer-bottom-bar{
  background-color: $white3;
  padding: 15px 0;
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      font-size: 14px;
      font-family: 'Poppins';
    }
    a {
      width: 60px;
      height: 60px;
      color: #899198;
      z-index: 2;
      background-color: #f3f7f9;
      border: 1px solid #f3f7f9;
      border-radius: 50px;
      justify-content: center;
      align-items: center;
      line-height: 0;
      text-decoration: none;
      transition: all .2s;
      display: none;
      position: fixed;
      bottom: 1em;
      left: 1em;
      box-shadow: 0 4px 12px rgb(0 0 0 / 30%);
      i{
        font-size: 24px;
      }
      &:hover{
        color: $charcoal-text;
        background-color:  $white;
      }
      @media (max-width: $small-tablet) {
        margin-right: 40px;
      }
    }
  }
}
