//all common styling will add here

.primary-button {
  padding: 12px 40px;
  border-radius: 25px;
  border: solid 1px $aqua3;
  transition: all 0.5s ease-out;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: -0.45px;
  color: $aqua3;
  height: 50px;
  line-height: 25px;
  &:hover {
    transition: all 0.5s ease-out;
    border: 1px solid transparent;
    background-position: 99% 50%;
    background: linear-gradient(100deg, $topaz 0%, $bright-cyan 100%);
    color: $white;
  }
  a {
    color: $aqua3;
    &:hover {
      color: $white;
    }
  }
}
.learn-more-btn {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: -0.45px;
  color: $aqua3;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    letter-spacing: -0.45px;
    color: $aqua3;
    gap: 12px;
  }
}
.download-btn {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: -0.45px;
  color: $aqua3;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  z-index: 1;
  &:hover {
    color: $aqua3;
  }
  &:focus {
    color: $aqua3;
  }
}
.common-parah {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.09px;
  text-align: left;
  color: #000;
}
.common-quote-para {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: -0.07px;
  text-align: left;
  color: $textBlack;
  margin-bottom: 25px;
  //-webkit-line-clamp: 3;
  //-webkit-box-orient: vertical;
  //display: -webkit-box;
  //overflow: hidden;
}
.common-quote-author {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  //font-style: italic;
  line-height: 1.93;
  letter-spacing: -0.07px;
  text-align: left;
  color: #000;
}
.main-heading {
  font-family: "Poppins", sans-serif;
  font-size: 4.1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin: 0;
}
.sub-heading {
  font-family: "Poppins", sans-serif;
  font-size: 2.3rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin: 0;
}
.common-item-title {
  font-family: "Poppins", sans-serif;
  font-size: 23px;
  font-weight: 500;
  color: #000;
  margin: 0;
}
.bg-element {
  .right-coming-object.balls {
    margin-right: -100px;
  }
  .left-coming-object.balls {
    margin-left: -100px;
  }
  &.top.left {
    position: absolute;
    left: 0;
    top: 90px;
  }
  &.top.right {
    position: absolute;
    right: 0;
    top: 90px;
  }
  &.center.left {
    position: absolute;
    left: 0;
    top: 50%;
  }
  &.center.right {
    position: absolute;
    right: 0;
    top: 50%;
  }
  &.bottom.left {
    position: absolute;
    left: 0;
    bottom: 90px;
  }
  &.bottom.right {
    position: absolute;
    right: 0;
    bottom: 90px;
  }
  .lines-pattern {
    width: 40%;
    right: 0;
    position: absolute;
    opacity: 0.5;
  }
  @media (max-width: $small-laptop) {
    display: none;
  }
}
//Section Titles
.section-title {
  text-align: center;
  max-width: 960px;
  margin: 0 auto 50px;
  @media (max-width: $small-tablet) {
    margin: 0 auto 50px;
    max-width: 100%;
  }
  &.large {
    max-width: 100%;
  }
  h2 {
    font-size: 4.1rem;
    font-weight: 600;
    color: $textBlack;
    margin-bottom: 24px;
    @media (max-width: $small-tablet) {
      font-size: 3rem;
    }
  }
  h3 {
    font-size: 24px;
    color: $textBlack;
    font-weight: 500;
    margin-bottom: 24px;
    @media (max-width: $small-tablet) {
      font-size: 16px;
    }
  }
  p {
    font-size: 20px;
    color: $textBlack;
    font-weight: 300;
    @media (max-width: $small-tablet) {
      font-size: 14px;
    }
  }
  &.large {
    h1 {
      max-width: 75%;
      margin: 0 auto 24px;
      font-size: 4.5rem;
      font-weight: 600;
      color: $textBlack;
    }
    h2 {
      max-width: 65%;
      margin: 0 auto 24px;
      font-size: 3rem;
      @media (max-width: $small-tablet) {
        max-width: 100%;
        font-size: 3rem;
      }
    }
  }
}
section {
  overflow: hidden;
  &.main-banner-section {
    overflow: initial;
  }
}
.bgdark {
  background-color: $pale-grey-bg !important;
}
.bgwhite {
  background-color: $white !important;
}
.container {
  @media (max-width: $large-laptop) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-0 {
  margin-bottom: 0;
}
//Common gradient border

.gradient-border {
  border-radius: 10px;
  //overflow: hidden;
  border: 1px solid transparent;
  background: linear-gradient(
    to top,
    $tiffany-blue,
    $pale-purple,
    $tiffany-blue,
    $pale-purple
  );
  background-clip: padding-box;
  padding: 1px;
  position: relative;
  .gradient-border-inner {
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    min-height: 223px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

//Twitter News module styling
.twitter-news-section {
  width: 300px;
  position: absolute;
  right: -150px;
  top: -50px;
  .news-list {
    border: 1px solid $tiffany-blue;
    border-radius: 10px;
    position: relative;
    //padding: 12px;
    width: 100%;
    max-height: 650px;
    overflow: scroll;
    overflow-x: hidden;
    .twitter-timeline {
      .tweet-text {
        padding: 12px;
        display: inline-block;
      }
      &.twitter-timeline-rendered {
        margin: -1px !important;
      }
      iframe {
        width: 101% !important;
      }
    }
    .single-news {
      margin-bottom: 24px;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      .news-header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .header-img {
          width: 46px;
          height: 46px;
          border-radius: 28px;
        }
        .header-text {
          font-family: "Poppins", sans-serif;
          padding-left: 12px;
          text-align: left;
          width: 70%;
          .light {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
          }
          .dark {
            font-size: 12px;
            color: $light-text;
            margin: 0;
          }
        }
        .twitter-bird {
          width: 29px;
          height: 23px;
        }
      }
      .news-content {
        .news-img {
          width: 100%;
          height: 160px;
          border-radius: 10px;
          margin-bottom: 12px;
        }
        .news-text {
          font-size: 13px;
          font-weight: 300;
          margin: 0;
          height: 95px;
          overflow: hidden;
        }
      }
    }
    .twitter-timeline {
      width: 100%;
      iframe {
        width: 100%;
      }
    }
  }
  .twitter-icon {
    position: absolute;
    z-index: 2;
    left: -45px;
    top: 100px;
    width: 70px;
    height: 80px;
    color: $twitterColor;
    background-color: $white;
    font-size: 36px;
    box-shadow: 1px 1px 10px $item-shadow;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $small-tablet) {
      width: 60px;
      height: 60px;
      font-size: 30px;
      top: -40px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  @media (max-width: 1500px) {
    right: -50px;
  }
  @media (max-width: $large-laptop) {
    right: auto;
    position: relative;
  }
  @media (max-width: $small-tablet) {
    position: relative;
    right: auto;
    width: 100%;
  }
}
//single quote module styling

.modal {
  justify-content: center;
  align-items: center;
  .modal-dialog {
    @media (min-width: $small-tablet) {
      width: 960px;
    }
    .modal-content {
      padding: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 32px;
      overflow: hidden;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 50px;
        left: -40px;
        width: 200px;
        height: 35px;
        background-image: url("../assets/images/left-coming-object.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @media (max-width: $small-tablet) {
          display: none;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 30px;
        right: -155px;
        transform: rotate(190deg);
        opacity: 0.2;
        width: 260px;
        height: 158px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../assets/images/title-balls.png");
        @media (max-width: $small-tablet) {
          display: none;
        }
      }
      @media (max-width: $small-tablet) {
        padding: 50px 25px;
      }
      .modal-header {
        border-bottom: none;
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        width: 80%;
        .close {
          width: 30px;
          height: 30px;
          border: 2px solid #0fb4ba;
          border-radius: 30px;
          justify-content: center;
          align-items: center;
          margin: 0;
          display: flex;
          position: absolute;
          top: 20px;
          right: 20px;
          color: #0fb4ba;
          opacity: 1;
        }
        img {
          width: 250px;
        }
        .modal-title {
          font-size: 46px;
          font-weight: 600;
          color: #0fb4ba;
        }
        .modal-sub-text {
          font-size: 18px;
        }
      }
      .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        width: 80%;
        padding: 0;
        flex-wrap: wrap;
        @media (max-width: $small-tablet) {
          padding: 15px 0px;
          width: 100%;
          flex-wrap: wrap;
        }
        p {
          font-size: 22px;
          color: $textBlack;
        }
        ::placeholder {
          color: #cfcfcf ;
          opacity: 1; /* Firefox */
        }
        
        ::-ms-input-placeholder { /* Edge 12 -18 */
          color: #cfcfcf ;
        }
        .input-field {
          width: 45%;
          display: flex;
          height: 50px;
          border-radius: 10px;
          text-align: left;
          border: 1px solid #8f8f8f;
          outline: none;
          box-shadow: none;
          padding: 0 20px 0 20px;
          @media (max-width: $small-tablet) {
            width: 100%;
          }
        }
        .messagetext-field{
          width: 95%;
          height: 180px;
          display: flex;
          border-radius: 10px;
          text-align: left;
          border: 1px solid #8f8f8f;
          outline: none;
          box-shadow: none;
          padding: 20px;
          @media (max-width: $small-tablet) {
            width: 100%;
          }
        }
      }
      .modal-footer {
        padding: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 32px;
        border-top: none;
        width: 80%;
        .primary-button {
          width: 200px;
          &.gradient-btn {
            border: 1px solid transparent;
            background-image: linear-gradient(
              100deg,
              $topaz 0%,
              $bright-cyan 100%
            );
            color: #fff;
          }
        }
      }
    }
  }
  &.in {
    display: flex !important;
  }
}
.single-quote {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 1px;
  background: linear-gradient(
    to top,
    $tiffany-blue,
    $pale-purple,
    $tiffany-blue,
    $pale-purple
  );
  background-clip: padding-box;
  border-color: transparent;
  padding: 1px;
  max-width: 490px;
  margin: 0 12px;
  .single-quote-inner {
    background: #fff;
    padding: 40px 36px 25px;
    border-radius: 10px;
    min-height: 223px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .ninepatch-icon {
    width: 120px !important;
  }
  .comma-icon {
    position: absolute;
    bottom: 35px;
    right: 30px;
    //margin-left: -45px;
    filter: grayscale(1);
    opacity: 0.8;
    width: 90px;
    @media (max-width: $small-laptop) {
      display: none;
    }
  }
  .common-quote-para {
    margin-bottom: 24px;
    z-index: 1;
    &:before {
      content: "\f10e";
      color: $quoteIcon3;
      position: absolute;
      top: -30px;
      left: 16px;
      font-size: 85px;
      font-family: var(--fa-style-family, "Font Awesome 6 Free");
      font-weight: var(--fa-style, 900);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      z-index: -1;
      opacity: 0.5;
    }
  }
  .common-quote-author {
    margin-bottom: 0;
    //font-weight: 500;
  }
}
.footer-bottom-bar {
  .btn-group-vertical > .btn-group:after,
  .btn-group-vertical > .btn-group:before,
  .btn-toolbar:after,
  .btn-toolbar:before,
  .clearfix:after,
  .clearfix:before,
  .container-fluid:after,
  .container-fluid:before,
  .container:after,
  .container:before,
  .dl-horizontal dd:after,
  .dl-horizontal dd:before,
  .form-horizontal .form-group:after,
  .form-horizontal .form-group:before,
  .modal-footer:after,
  .modal-footer:before,
  .modal-header:after,
  .modal-header:before,
  .nav:after,
  .nav:before,
  .navbar-collapse:after,
  .navbar-collapse:before,
  .navbar-header:after,
  .navbar-header:before,
  .navbar:after,
  .navbar:before,
  .pager:after,
  .pager:before,
  .panel-body:after,
  .panel-body:before,
  .row:after,
  .row:before {
    display: table;
  }
}
.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: none;
}

.close-expanded {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 100;
  color: #fff;
  opacity: 1;
  background-color: transparent;
}

#subscribeCaptcha {
  display: none;
}


.loading-spinner {
  display: none;
  top: 25% !important;
}

// Cookie Consent
#termsfeed-com---nb {
  * {
    font-family: Poppins, sans-serif;
  }
  .cc-nb-okagree,
  .cc-nb-reject {
    font-weight: normal;
    padding: 12px 40px;
    border-radius: 25px;
    border: solid 1px $aqua3;
    transition: all 0.5s ease-out;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    letter-spacing: -0.45px;
    color: $aqua3;
    height: 50px;
    line-height: 20px;
    &:hover {
      transition: all 0.5s ease-out;
      border: 1px solid transparent;
      background-position: 99% 50%;
      background: linear-gradient(100deg, $topaz 0%, $bright-cyan 100%);
      color: $white;
    }
    a {
      color: $aqua3;
      &:hover {
        color: $white;
      }
    }
  }

  .cc-nb-changep {
    background-color: transparent;
  }

  .cc-nb-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .cc-cp-foot-byline {
    visibility: hidden;
  }

  border-radius: 10px;
  //overflow: hidden;
  border: 1px solid transparent;
  background: linear-gradient(
    to top,
    $tiffany-blue,
    $pale-purple,
    $tiffany-blue,
    $pale-purple
  );
  background-clip: padding-box;
  padding: 1px;
  .cc-nb-main-container {
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    min-height: 223px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  background-color: #f5f7fa;
}

