@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "variables";
@import "common";
@import "custom-styles";
@import "testimonials";
@import "footer";
@import "header";
@import "_resources.scss";
@import "_pressreleases.scss";
@import "_careers.scss";



//all the styling code will go here
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: $white2;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: $white2;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: $gradient;
}
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  cursor: default;
}
.body-inner {
  //max-width: $max-width;
  //margin: 0 auto;
}

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  //top: 0;
  //left: 0;
  //text-align: center;
  background-color: rgba(1,1,1,0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 120px;
  height: 50px;
  position: relative;

  p {
    top: 0;
    padding: 0;
    margin: 0;
    color: $topaz;
    font-family: Poppins, sans-serif;
    animation: text 3.5s ease both infinite;
    font-size: 15px;
    letter-spacing: 1px;

    @keyframes text {
      0% {
        letter-spacing: 1px;
        transform: translateX(0px);
      }

      40% {
        letter-spacing: 2px;
        transform: translateX(26px);
      }

      80% {
        letter-spacing: 1px;
        transform: translateX(32px);
      }

      90% {
        letter-spacing: 2px;
        transform: translateX(0px);
      }

      100% {
        letter-spacing: 1px;
        transform: translateX(0px);
      }
    }
  }
  span {
    background-color: $topaz;
    border-radius: 50px;
    display: block;
    height: 16px;
    width: 16px;
    bottom: 0;
    position: absolute;
    transform: translateX(64px);
    animation: loading 3.5s ease both infinite;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: $pale-purple;
      border-radius: inherit;
      animation: loading2 3.5s ease both infinite;
    }

    @keyframes loading {
      0% {
        width: 16px;
        transform: translateX(0px);
      }

      40% {
        width: 100%;
        transform: translateX(0px);
      }

      80% {
        width: 16px;
        transform: translateX(64px);
      }

      90% {
        width: 100%;
        transform: translateX(0px);
      }

      100% {
        width: 16px;
        transform: translateX(0px);
      }
    }
    @keyframes loading2 {
      0% {
        transform: translateX(0px);
        width: 16px;
      }

      40% {
        transform: translateX(0%);
        width: 80%;
      }

      80% {
        width: 100%;
        transform: translateX(0px);
      }

      90% {
        width: 80%;
        transform: translateX(15px);
      }
      100% {
        transform: translateX(0px);
        width: 16px;
      }
    }
  }
}


#loading-image {
  position: absolute;
  top: 100px;
  left: 240px;
  z-index: 100;
}


ul{
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a{
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
    outline-offset: 0;
    text-decoration: none;
  }
}


p, h1, h2, h3, h4, h5{
  margin:0;
}

#more-text{
  display:none;
}

.search-over-section {
  background: #fff;
  padding: 80px 0px;
  position: relative;
  @media (max-width: $small-tablet) {
    padding: 50px 0px;
  }
  .bg-element{
    position: absolute;
    left: 0;
    bottom: 220px;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .row{
    display: flex;
    @media (max-width: $small-tablet) {
      flex-direction: column;
      gap: 80px;
    }
  }
  .col-md-4{
    @media (max-width: $small-tablet) {
      width: 100%;
    }
  }
  .search-over-inner {
    position: relative;
    .title-wrapper {
      position: relative;
      .title-balls {
        position: absolute;
        left: -82px;
        width: 75px;
        top: -6px;
        @media (max-width: $large-laptop) {
          display: none;
        }
      }
      .main-heading {

      }
      .sub-heading {
        //max-width: 540px;
        max-width: 100%;
      }
    }
    .search-content-wrapper {
      .text-wrapper{
        //max-width: 870px;
        max-width: 100%;
        margin: 40px 0;
        .common-parah{

        }
      }
      .quotes-wrapper {
        display: flex;
        //single quote styling from common
      }
    }
  }
}

.out-world-section {
  background: $pale-grey-bg;
  padding: 100px 20px;
  position: relative;
  .bg-element{
    position: absolute;
    right: 0;
    top: 90px;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .bg-element2{
    position: absolute;
    right: 0;
    bottom: 60px;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .out-world-inner {
    max-width: $inner-body-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-side {
      width: 60%;
      max-width: 660px;
      .common-parah{
        margin: 30px 0 40px;
      }
    }
    .quote-side {
      width: 40%;
      display: flex;
      justify-content: end;
      .single-quote {
        border: 1px solid $pale-purple;
        background: none;
        max-width: 350px;
        .single-quote-inner {
          background: transparent;
        }
      }
    }
  }
}

.trusted-client-wrapper {
  background: $pale-grey-bg;
  padding: 80px 0px;
  position: relative;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .trusted-client-inner {
    .drag-icon {
      width: 50px;
      position: absolute;
      right: -45px;
      top: 130px;
      opacity: 0.4;
      @media (max-width: $large-laptop) {
        right: -5px;
      }
      @media (max-width: $medium-laptop) {
        display: none;
      }
    }
    //styling from testimonial snippet
  }
}

.solutions-services-wrapper{
  //background: url("../assets/images/lines-bg.svg") no-repeat right #fff;
  padding: 80px 0;
  position: relative;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .bg-element{
    position: absolute;
    left: 0;
    top: 90px;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .solutions-services-inner{
    text-align: center;
    .main-heading {
      text-align: center;
      margin-bottom: 80px;
    }
    .solution-service-list{
      display: flex;
      justify-content: space-evenly;
      @media (max-width: $small-tablet) {
        justify-content: center;
        flex-direction: column;
        width: 100%;
        gap: 50px;
      }
      .single-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .gradient-wrapper{
          margin-bottom: 32px;
          width: 230px;
          height: 135px;
          border: none;
          background-image: radial-gradient(circle, $topaz, $bright-cyan, $pale-purple);
          @media (max-width: $small-tablet) {
            width: 70%;
            height: 300px;
          }
          @media (max-width: $phablet) {
            width: 100%;
          }
          .gradient-inner{
            min-height: auto;
            height: 100%;
            display: flex;
            align-items: center;
           .icon {
              width: 64px;
              height: 64px;
              background-size: contain !important;
              background-repeat: no-repeat !important;
              background-position: center center !important;
              &.solutions-icon{
                background-image: url("../assets/images/stella-solutions-dark.svg");
              }
              &.service-icon{
                background-image: url("../assets/images/development-service-dark.svg");
              }
             &.consulting-icon{
               background-image: url("../assets/images/hie-consulting-dark.svg");
             }
             @media (max-width: $small-tablet) {
               width:100px;
               height: 100px;
             }
            }
          }
        }
        .common-item-title{
          margin-bottom: 16px;
        }
        .divider{
          width: 227px;
          height: 1px;
          background: #000;
          display: block;
          margin: 0 auto 16px;
        }
        .common-quote-para{
          text-align: center;
          max-width: 234px;
        }
        &:hover{
          .gradient-wrapper{
              box-shadow: $teal-box-shadow;
            .gradient-inner{
              //background: transparent;
              background-image: linear-gradient(to top, $topaz, $bright-cyan);
              .icon {
                &.solutions-icon{
                  background-image: url("../assets/images/stella-solutions-white.svg");
                  width: 77px;
                  height: 77px;
                }
                &.service-icon{
                  background-image: url("../assets/images/development-service-white.svg");
                }
                &.consulting-icon{
                  background-image: url("../assets/images/hie-consulting-white.svg");
                }
              }
            }
          }
        }
      }
    }
  }
}

.relationship-wrapper{
  //background: $pale-grey-bg;
  padding: 80px 0;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .marquee-wrapper{
    //overflow: hidden;
    width: 6000px;
    .marquee-content {
      display: flex;
      animation: scrolling 20s linear infinite running;
      align-items: center;
      &:hover{
        animation-play-state: paused;
      }
      .marquee-item {
        margin: 5px 24px;
        img {
          width: 150px;
        }
      }
    }
  }
}

.case-studies-wrapper{
  background: $pale-grey-bg;
  padding: 100px 0;
  position: relative;
  .case-studies-inner {
    .main-heading {
      text-align: center;
      margin: 0 0 80px;
    }
    .case-studies-list {
      display: flex;
      font-family: 'Poppins', sans-serif;
      justify-content: center;
      @media (max-width: $small-tablet) {
        flex-direction: column;
        gap: 50px;
      }
      .single-case-study {
        background-color: $white2;
        padding: 16px;
        width: 30%;
        margin: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .case-img {
          margin-bottom: 32px;
          max-width: 330px;
          width: 100%;
          filter: grayscale(1);
          transition: 0.2s all;
          @media (max-width: $small-tablet) {
            max-width: 70%;
          }
          @media (max-width: $phablet) {
            max-width: 100%;
          }
        }
        .case-title {
          font-size: 22px;
          font-weight: 500;
          text-align: center;
          color: #000;
          margin: 0 0 24px;
        }
        .case-parah {
          letter-spacing: -.08px;
          text-align: center;
          color: $textBlack;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.38;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          margin-bottom: 25px;
        }
        &:hover{
          .case-img {
            filter: grayscale(0);
          }
        }
        @media (max-width: $small-tablet) {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}

.stella-moments-wrapper{
  padding: 55px 0px;
  overflow: hidden;
  background: #fff;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .stella-moments-inner {
    display: flex;
    .text-side{
      width: 50%;
      .title-wrap {
        margin-bottom: 30px;
        position: relative;
        .yellow-balls {
          position: absolute;
          left: -26px;
          width: 44px;
          @media (max-width: $small-laptop) {
            display: none;
          }
        }
        .main-heading {
          position: relative;
        }
      }
      .common-parah {
        margin-bottom: 20px;
      }
      @media (max-width: $large-laptop) {
        width: 100%;
      }
    }
    .img-side {
      width: 50%;
      position: relative;
      @media (max-width: $large-laptop) {
        display: none;
      }
      .stella-moment-img {
        position: absolute;
        top: -55px;
        right: -220px;
        height: 520px;
      }
    }
  }
}

//About Page Starts Here

.about-section {
  padding: 80px 0px;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .about-section-inner {
    max-width: 1350px;
    margin: 0 auto;
  }
 .section-title {
    margin-bottom: 0 !important;
   p{
     margin-bottom: 20px;
   }
   #hidden-text {
     display: none;
   }
  }
  .primary-button {
    margin-top: 20px;
    &.gradient-btn{
      border: 1px solid transparent;
      background-image: linear-gradient(100deg,$topaz 0%, $bright-cyan 100%);
      color: #fff;
    }
  }
  &.experties{
    overflow: hidden;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 70px;
      left: -70px;
      opacity: 0.3;
      transform: rotate(15deg);
      width: 80px;
      height: 95px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../assets/images/title-balls.png');
      @media (max-width: $small-tablet) {
        display: none;
      }
    }
    &:after{
      content: "";
      position: absolute;
      right: -15px;
      bottom: 160px;
      width: 260px;
      height: 30px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../assets/images/right-long-coming-object.png');
      @media (max-width: $small-tablet) {
        display: none;
      }
    }
    .experties-content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 80px;
      @media (max-width: $large-tablet) {
        flex-direction: column;
        gap: 50px;
      }
      .section-title {
        position: relative;
        text-align: left;
        flex-basis: 70%;
        @media (max-width: $large-tablet) {
          flex-basis: 100%;
        }
        p{
          margin-bottom: 24px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .experties-box {
        border-radius: 10px;
        background-color: #c6efef;
        box-shadow: 1px 1px 6px rgb(0 0 0 / 10%);
        padding: 25px;
        flex-basis: 30%;
        z-index: 2;
        @media (max-width: $large-tablet) {
          flex-basis: 80%;
          margin: 0 auto;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: #000;
          margin-bottom: 20px;
          span {
            font-weight: 700;
          }
        }
        ol {
          list-style-type: disc;
          padding-left: 20px;
          li {
            font-size: 16px;
            font-weight: 400;
            color: #000;
          }
        }
      }
    }

    .button-wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 32px;
      margin-top: 10px;
      @media (max-width:640px) {
        flex-wrap: wrap;
      }
    }
  }
  .consultancy-outer-wrapper{
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 32px 32px;
      margin-bottom: 32px;
      flex-wrap: wrap;
      .white-space{
        background: transparent;
        border-width: 0px;
        min-width: 50px;
        @media (max-width: $large-laptop) {
         display: none;
        }
      }
      li{
        height: 50px;
        min-width: 150px;
        padding: 0 12px;
        //background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, $tiffany-blue, $pale-purple, $tiffany-blue, $pale-purple);
        //background: linear-gradient(to top, #78dfdf, #b2a3d2, #78dfdf, #b2a3d2) padding-box padding-box;
        border: 1px solid rgba(0, 179, 185, 0.5);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 179, 185, 0.1);
        //background-image: linear-gradient(to right, $topaz, $bright-cyan);
        .state-img {
          &.Arizona {
            background: url("../assets/images/HIE-states/Arizona-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 26px;
            height: 30px;
          }
          &.Arkansas {
            background: url("../assets/images/HIE-states/Arkansas-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 33px;
            height: 30px;
          }
          &.California {
            background: url("../assets/images/HIE-states/California-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 35px;
            height: 37px;
          }
          &.Idaho {
            background: url("../assets/images/HIE-states/Idaho-02.svg") no-repeat center transparent;
            background-size: contain;
            margin-top: -5px;
            width: 26px;
            height: 37px;
          }
          &.Kentucky {
            background: url("../assets/images/HIE-states/Kentucky-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 48px;
            height: 45px;
            margin-top: -12px;
          }
          &.Maryland {
            background: url("../assets/images/HIE-states/Maryland-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 44px;
            height: 43px;
          }
          &.Michigan {
            background: url("../assets/images/HIE-states/Michigan-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 38px;
            height: 34px;
          }
          &.Nebraska {
            background: url("../assets/images/HIE-states/Nebraska-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 44px;
            height: 35px;
          }
          &.Nevada {
            background: url("../assets/images/HIE-states/Nevada-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 26px;
            height: 33px;
          }
          &.New-York {
            background: url("../assets/images/HIE-states/New York-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 44px;
            height: 31px;
          }
          &.North-Dakota {
            background: url("../assets/images/HIE-states/North Dakota-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 32px;
            height: 30px;
          }
          &.Tennessee {
            background: url("../assets/images/HIE-states/Tennessee-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 47px;
            height: 24px;
          }
          &.Utah {
            background: url("../assets/images/HIE-states/Utah-02.svg") no-repeat center transparent;
            background-size: contain;
            width: 29px;
            height: 32px;
          }
        }
        span {
          font-size: 17px;
          font-weight: 400;
          line-height: 1.76;
          letter-spacing: -0.09px;
          text-align: center;
          color: #000;
          &:nth-child(2){
            margin-left: 10px;
          }
        }
        &:hover {
          background-image: linear-gradient(100deg,$topaz 0%, $bright-cyan 100%);
          .state-img {
            &.Arizona {
              background: url("../assets/images/HIE-states/Arizona-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Arkansas {
              background: url("../assets/images/HIE-states/Arkansas-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.California {
              background: url("../assets/images/HIE-states/California-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Idaho {
              background: url("../assets/images/HIE-states/Idaho-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Kentucky {
              background: url("../assets/images/HIE-states/Kentucky-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Maryland {
              background: url("../assets/images/HIE-states/Maryland-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Michigan {
              background: url("../assets/images/HIE-states/Michigan-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Nebraska {
              background: url("../assets/images/HIE-states/Nebraska-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Nevada {
              background: url("../assets/images/HIE-states/Nevada-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.New-York {
              background: url("../assets/images/HIE-states/New York-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.North-Dakota {
              background: url("../assets/images/HIE-states/North Dakota-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Tennessee {
              background: url("../assets/images/HIE-states/Tennessee-01.svg") no-repeat center transparent;
              background-size: contain;
            }
            &.Utah {
              background: url("../assets/images/HIE-states/Utah-01.svg") no-repeat center transparent;
              background-size: contain;
            }
          }
          span{
            color: #fff;
          }
        }
      }
    }
  }
}

.ceo-section {
  padding: 30px 0 45px;
  background-color: $pale-grey-bg;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .ceo-inner-content{
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $small-tablet) {
      flex-direction: column;
      gap: 42px;
    }
    .ceo-info-wrapper{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      @media (max-width: $small-tablet) {
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .image-wrapper {
        width: 350px;
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;
        @media (max-width: $small-laptop) {
          width: 300px
        }
        @media (max-width: $small-tablet) {
          width: 50%;
        }
        img {
          width: 90%;
          object-fit: contain;
        }
      }
      .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-right: 16px;
        @media (max-width: $small-tablet) {
          justify-content: center;
          align-items: center;
          padding-right: 0;
        }
        h3 {
          font-size: 26px;
          color: $ceoTitle;
          font-weight: 600;
          //text-transform: uppercase;
          margin-bottom: 5px;
          @media (max-width: $small-tablet) {
            font-size: 22px;
            margin-bottom: 0px;
          }
        }
        p {
          color: $textBlack;
          font-size: 22px;
          @media (max-width: $small-tablet) {
            font-size: 26px;
          }
        }
      }
    }
    .ceo-quote-wrapper{
      .ceo-quote-content{
        position: relative;
        p{
          font-size: 20px;
          font-weight: 500;
          position: relative;
          z-index: 2;
        }
        &:before{
          content: "\f10e";
          color: $quoteIcon3;
          position: absolute;
          top: -40px;
          left: 5px;
          font-size: 85px;
          font-family: var(--fa-style-family,"Font Awesome 6 Free");
          font-weight: var(--fa-style,900);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }
    }
  }
}

.value-section {
  padding: 80px 0px;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: calc(100% - 55%);
    left: -15px;
    width: 206px;
    height: 47px;
    background-image: url('../assets/images/left-coming-object.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 160px;
    right: -100px;
    transform: rotate(15deg);
    opacity: 0.5;
    width: 260px;
    height: 158px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/title-balls.png');
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .value-content-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .value-image-wrapper{
      text-align: center;
      width: 70%;
      margin: 0 auto;
      height: 880px;
      @media (max-width: $small-tablet) {
        width: 100%;
        height: 585px;
      }
      @media (max-width: $small-phone) {
        height: 480px;
      }
      img{
        width: 100%;
      }
      .v-image{
        width: 100%;
        height: inherit;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position: center center !important;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
        transition: opacity .2s ease-out;
        .v-box {
          position: absolute;
          left: 70px;
          right: 0;
          height: 105px;
          width: calc(100% - 25%);
          margin: 0 auto;
          border-radius: 50px;
          z-index: 2;
          cursor: pointer;
          @media (max-width: $small-laptop) {
            width: 86%;
          }
          @media (max-width: $small-tablet) {
            width: 77%;
            height: 70px;
          }
          @media (max-width: $small-phone) {
            width: 90%;
            height: 42px;
            left: 30px;
          }
          @media (max-width: $iphone-pro) {
            height: 37px;
          }
          @media (max-width: $smallest-phone) {
            height: 31px;
          }
          &.v1-box {
            top: 154px;
            @media (max-width: $small-tablet) {
              top: 103px;
            }
            @media (max-width: $small-phone) {
              top: 121px;
            }
            @media (max-width: $iphone-pro) {
              top: 137px;
            }
            @media (max-width: $smallest-phone) {
              top:154px;
            }
          }
          &.v2-box {
            top: 323px;
            @media (max-width: $small-tablet) {
              top: 215px;
            }
            @media (max-width: $small-phone) {
              top: 193px;
            }
            @media (max-width: $iphone-pro) {
              top: 199px;
            }
            @media (max-width: $smallest-phone) {
              top: 206px;
            }
          }
          &.v3-box {
            top: 490px;
            @media (max-width: $small-tablet) {
              top: 325px;
            }
            @media (max-width: $small-phone) {
              top: 262px;
            }
            @media (max-width: $iphone-pro) {
              top: 260px;
            }
            @media (max-width: $smallest-phone) {
              top: 257px;
            }
          }
          &.v4-box {
            top: 662px;
            @media (max-width: $small-tablet) {
              top: 440px;
            }
            @media (max-width: $small-phone) {
              top: 334px;
            }
            @media (max-width: $iphone-pro) {
              top: 323px;
            }
            @media (max-width: $smallest-phone) {
              top: 310px;
            }
          }
        }
        &.v-default{
          opacity: 1;
          transition: opacity .2s ease-out;
          background-image: url("../assets/images/v-default.svg");
          .v1-image{
            background-image: url("../assets/images/v1.svg");
            &.show{
              opacity: 1;
              transition: opacity .2s ease-out;
            }
          }
          .v2-image{
            background-image: url("../assets/images/v2.svg");
            &.show{
              opacity: 1;
              transition: opacity .2s ease-out;
            }
          }
          .v3-image{
            background-image: url("../assets/images/v3.svg");
            &.show{
              opacity: 1;
              transition: opacity .2s ease-out;
            }
          }
          .v4-image{
            background-image: url("../assets/images/v4.svg");
            &.show{
              opacity: 1;
              transition: opacity .2s ease-out;
            }
          }
        }

      }
    }

    .value-box {
      width: 50%;
      margin: 0 auto;
      background-color: #f4f4f4;
      border-radius: 20px 50px 0 0;
      padding-bottom: 50px;
      .value-title {
        padding: 20px;
        text-align: center;
        h2 {
          font-size: 70px;
          font-weight: 700;
          color: #319b98;
        }
      }
      .ribbon-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        width: 100%;
        .ribbon-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          width: 100%;
          padding: 10px 30px;
          height: 120px;
          background-image: linear-gradient(100deg, #11adb3 0%, #5ee7df 100%);
          border: 1px solid #fff;
          box-shadow: 0px 20px 20px rgb(0 0 0 / 15%);
          transition: filter 0.2s ease-out;
          filter: grayscale(1);
          .ribbon-text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: calc(100% - 65px);
            gap: 12px;
            .ribbon-title {
              width: 70px;
              word-break: break-all;
              h3 {
                font-size: 16px;
                font-weight: 600;
                color: #fff;
              }
            }
            .ribbon-content {
              position: relative;
              &:before {
                content: "";
                position: absolute;
                left: -10px;
                height: 60px;
                top: 10px;
                width: 1px;
                background-color: #fff;
              }
              ul{
                li{
                  font-size: 14px;
                  font-weight: 300;
                  color: #fff;
                }
              }
            }
          }
          .ribbon-icon {
            width: 50px;
            height: 50px;
            .icon-wrapper {
              width: 100%;
              height: 100%;
              border: 1px solid #fff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              .icon {
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center center !important;
                width: 30px;
                height: 30px;
                &.icon1{
                  background-image: url("../assets/images/stella-solutions-white.svg");
                }
                &.icon2{
                  background-image: url("../assets/images/hie-consulting-white.svg");
                }
                &.icon3{
                  background-image: url("../assets/images/development-service-white.svg");
                }
                &.icon4{
                  background-image: url("../assets/images/easy-to-use.svg");
                }
              }
            }
          }
          .ribbon-image {
            width: 100%;
            height: 100%;
            background-size: contain !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            &.ribbon1{
              background-image: url("../assets/images/v1-before.svg");
            }
            &.ribbon3{
              background-image: url("../assets/images/v2-before.svg");
            }
            &.ribbon3{
              background-image: url("../assets/images/v3-before.svg");
            }
            &.ribbon4{
              background-image: url("../assets/images/v4-before.svg");
            }
          }
          &:hover{
            filter: grayscale(0);
            .ribbon-image{
              &.ribbon1{
                background-image: url("../assets/images/v1-after.svg");
              }
              &.ribbon3{
                background-image: url("../assets/images/v2-after.svg");
              }
              &.ribbon3{
                background-image: url("../assets/images/v3-after.svg");
              }
              &.ribbon4{
                background-image: url("../assets/images/v4-after.svg");
              }
            }
          }
        }
      }
    }
  }

}

.team-section {
  padding: 80px 0;
  background-color: $pale-grey-bg;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
    .row{
      margin: 0;
    }
    .col-md-12{
      padding: 0;
    }
  }
  &:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 170px;
    width: 210px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/right-coming-object.png');
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .team-outer-wrapper {
    width: 100%;
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: -40px;
      top: calc(100% - 650px);
      width: 190px;
      height: 270px;
      background-image: url('../assets/images/circle.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @media (max-width: $small-tablet) {
        display: none;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 60px;
      right: -250px;
      width: 414px;
      height: 50px;
      background-image: url('../assets/images/right-long-coming-object.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @media (max-width: $small-tablet) {
        display: none;
      }
    }
    .team-inner-wrapper{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 50px;
      z-index: 1;
      @media (max-width: $small-tablet) {
        margin-bottom: 50px;
      }
      @media (max-width: $small-phone) {
        flex-direction: column;
        z-index: 2;
        gap: 50px;
      }
      .team-items {
        position: relative;
        width: 165px;
        height: 450px;
        border-radius: 10px;
        padding: 0 25px;
        cursor: pointer;
        transition: width 0.5s ease-in-out;
        .team-content {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: $white;
          img{
            position: relative;
            z-index: 0;
            display: block;
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            border-radius: 10px;
          }
          .team-desc {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-image: -webkit-gradient(linear,left top,left bottom,color-stop(50%,transparent),to(rgba(0,0,0,.8)));
            background-image: linear-gradient(180deg,transparent 50%,rgba(0,0,0,.8));
            color: $white;
            border-radius: 10px;
            .team-before {
              position: absolute;
              bottom: 24px;
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              width: 85px;
              height: 85px;
              -webkit-box-pack: start;
              -webkit-justify-content: flex-start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -ms-flex-align: center;
              align-items: center;
              opacity: 1;
              transition: 0.6s ease-in;
              -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              transform: rotate(-90deg);
              .team-title {
                flex: none;
                font-size: 26px;
                font-weight: 600;
                padding-left: 20px;
              }
              .square-box {
                z-index: -1;
                width: 100%;
                height: 100%;
                background-color: $squareBox;
                top: 0;
                display: block;
                position: absolute;
              }
              @media (max-width: $small-phone) {
               opacity: 0;
              }
            }
            .team-after {
              padding: 20px;
              width: 100%;
              height: 100%;
              opacity: 0;
              transition: 0.5s ease-out;
              .social-media-content{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 20px;
                gap: 12px;
                a{
                  color: $white;
                  font-size: 20px;
                }
              }
              .after-content{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                position: absolute;
                bottom: 24px;
                .team-title {
                  -webkit-box-flex: 0;
                  -webkit-flex: 0 0 auto;
                  -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
                  font-size: 41px;
                  font-weight: 600;
                  line-height: 1.1em;
                }
                .team-designation {
                  display: -webkit-box;
                  display: -webkit-flex;
                  display: -ms-flexbox;
                  display: flex;
                  overflow: hidden;
                  height: 0%;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                  -webkit-flex-direction: column;
                  -ms-flex-direction: column;
                  flex-direction: column;
                  -webkit-box-pack: center;
                  -webkit-justify-content: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  -webkit-box-align: center;
                  -webkit-align-items: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-flex: 0;
                  -webkit-flex: 0 0 auto;
                  -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
                  -webkit-transition: .6s;
                  transition: .6s;
                  font-size: 20px;
                  font-weight: 300;
                  text-align: left;
                }
              }
              @media (max-width: $small-phone) {
                opacity: 1;
              }
            }
          }
        }
        &:hover,  &.default{
          width: calc(100% - 495px);
          transition: width 0.5s ease-in-out;
          @media (max-width: $small-laptop) {
            width: calc(100% - 450px);
          }
          @media (max-width: $large-tablet) {
            width: calc(100% - 420px);
          }
          @media (max-width: $small-tablet) {
            width: calc(100% - 360px);
          }
          @media (max-width: $large-phone) {
            width: calc(100% - 280px);
          }
          @media (max-width: $small-phone) {
            width: 100%;
          }
          .team-content {
            box-shadow: 4px 4px 10px rgb(41 41 41 / 40%);
            .team-desc {
              .team-before{
                opacity: 0;
                transition: 0.6s ease-out;
              }
              .team-after{
                opacity: 1;
                transition: 0.5s ease-in;
                transition-delay: 0.2s;
              }
            }
          }
        }
        @media (max-width: $small-laptop) {
          padding: 0 15px;
          width: 150px;
        }
        @media (max-width: $large-tablet) {
          padding: 0 10px;
          width: 140px;
        }
        @media (max-width: $small-tablet) {
          padding: 0 5px;
          width: 120px;
        }
        @media (max-width: $large-phone) {
          padding: 0 5px;
          width: 95px;
        }
        @media (max-width: $small-phone) {
          width: 100%;
          padding: 0;
          overflow: hidden;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

//About Page Ends Here

//Product Page Starts Here

.about-equinox {
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/about-bg-image.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .row {
    //display: flex;
    //justify-content: center;
    //align-items: flex-end;
    .content-wrapper{
      //max-width: 745px;
      @media (max-width: $small-tablet) {
        max-width: 100%;
      }
      .image-wrapper{
        width: 368px;
        margin-bottom: 70px;
        @media (max-width: $small-tablet) {
          margin: 0 auto 50px;
          width: 100%;
        }
        img{
          max-width: 360px;
          width: 100%;
          object-fit: contain;
        }
      }
      h2{
        //max-width: 745px;
        max-width: 1062px;
        margin-bottom: 50px;
        color: $textBlack;
        font-size: 27px;
        font-weight: 600;
      }
      .equinox-content{
        margin-bottom: 50px;
        .equinox-ul {
          list-style-type: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          @media (max-width: $small-tablet) {
            flex-direction: column;
            align-items: start;
          }
          li{
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            position: relative;
            padding-left: 20px;
            @media (max-width: $small-tablet) {
              font-size: 18px;
            }
            &:before{
              content: "";
              position: absolute;
              left: 0;
              top: 10px;
              width: 5px;
              height: 5px;
              background-color: #16bac1;
              border-radius: 20px;}
          }
        }
        .content-image{
          float: right;
          margin-left: 30px;
          width: 500px;
          @media (max-width: $small-laptop) {
            width: 450px;
          }
          @media (max-width: $large-tablet) {
            width: 400px;
          }
          @media (max-width: $small-tablet) {
            width: 350px;
          }
          @media (max-width: $large-phone) {
            width: 300px;
          }
          @media (max-width: $phablet) {
            float: none;
            margin-left: 0px;
            width:100%;
          }
          img{
            max-width: 100%;
            width: 100%;
          }
        }
        p{
          margin-bottom: 30px;
          color: $textBlack;
          font-size: 20px;
          line-height: 30px;
          font-weight: 300;
          &:last-child{
            margin-bottom: 0;
          }
          @media (max-width: $phablet) {
            font-size: 18px;
          }
        }
      }
    }
    //@media (max-width: $small-tablet) {
    //  flex-direction: column;
    //  gap: 32px;
    //  justify-content: space-between;
    //  align-items: center;
    //}
  }
}

.purpose-wrapper {
  padding: 80px 0;
  background-color: $pale-grey-bg;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: calc(100% - 85%);
    left: -15px;
    width: 206px;
    height: 47px;
    background-image: url('../assets/images/left-coming-object.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 160px;
    right: -100px;
    transform: rotate(15deg);
    opacity: 0.5;
    width: 260px;
    height: 158px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/title-balls.png');
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .section-title{
    margin: 0 auto 20px;
    @media (max-width: $small-tablet) {
      margin: 0 auto 50px;
    }
  }
  .purpose-outer-wrapper{
    margin-top: 34px;
    .card-outer-wrapper{
      margin-bottom: 50px;
      ul{
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: center;
        li{
          .flip-card {
            padding: 0px;
          }
        }
        @media (max-width:768px) {
          flex-wrap: wrap;
        }
      }
      .flip-card{
        padding: 15px;
        max-width: 100%;
        width: 26rem;
        height: 41rem;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-perspective: 1000px;
        perspective: 1000px;
        @media (max-width: $medium-laptop) {
          width: 100%;
          min-width: 26rem;
        }
        .card-front{
          width: 100%;
          height: 100%;
          padding: 20px;
          border-radius: 10px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          background-image: linear-gradient(100deg,$topaz 0%, $bright-cyan 100%);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
          -webkit-transition: -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          .front-content{
            width: 100%;
            height: 100%;
            position: relative;
            .image-wrapper{
              position: absolute;
              top: 30%;
              left: 50%;
              transform: translate(-50%, -30%);
              width: 75px;
              height: 75px;
              display: flex;
              justify-content: center;
              align-items: center;
              img{
                max-width: 100%;
                object-fit: contain;
              }
            }
            .content-wrapper{
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              gap: 10px;
              position: absolute;
              bottom: 0px;
              width: 100%;
              p{
                color: $white;
                font-size: 18px;
                font-weight: 600;
                width: calc(100% - 35px);
                word-break: break-word;
                text-align: left;
                i{
                  display: block;
                  font-style: normal;
                  font-size: 15px;
                }
                @media (max-width: $small-tablet) {
                  font-size: 16px;
                  font-weight: 500;
                }
              }
              span{
                //position: absolute;
                //bottom: 20px;
                //right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border: 1px solid $white;
                border-radius: 50px;
                font-size: 14px;
                color: $white;
              }
            }

          }
        }
        .card-back{
          padding: 20px;
          position: absolute;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
          -webkit-transition: -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          -webkit-box-shadow: 0 2rem 3rem rgb(0 0 0 / 5%);
          box-shadow: 0 2rem 3rem rgb(0 0 0 / 5%);
          background-color: #fff;
          .card-back-content{
            width: 100%;
            height: 100%;
            .image-wrapper{
              position: absolute;
              top: 20px;
              left: 20px;
              width: 44px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              img{
                max-width: 100%;
                width: 100%;
                object-fit: contain;
              }
            }
            .content-wrapper{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -40%);
              width: calc(100% - 50px);
              margin: 0 auto;
              h2{
                color: $textBlack;
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 10px;
                i{
                  display: block;
                  font-style: normal;
                }
              }
              p{
                color: $textBlack;
                text-align: center;
                @media (max-width: $small-tablet) {
                  font-weight: 500;
                }
              }
              ol {
                padding-left: 10px;
                margin: 0;
                li {
                  margin: 0 0 5px;
                  padding: 0 0 0 5px;
                  &:nth-child(2n+2) {
                    margin-top: 0;
                  }
                }
              }
              &.bigger-font {
                h2 {
                  font-size: 18px;
                }
                p{
                  font-size: 16px;
                }
              }
            }
          }
        }
        &:hover {
          .card-front {
            -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
          }
          .card-back {
            -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
          }
        }
      }
      &:nth-child(2n+2) {
        //margin-top: 6rem;
        @media (max-width: $medium-laptop) {
          margin-top: 0rem;
        }
      }
    }
    .owl-carousel {
      .owl-stage-outer {
        padding: 50px 0;
        overflow: hidden !important;
        .owl-stage {
         display: block;
        }
      }
       .owl-nav {
        margin-top: 0px;
         .owl-prev, .owl-next {
           position: absolute;
           top: 50%;
           transform: translateY(-50%);
         }
         .owl-prev {
           left: -60px;
           @media(max-width:768px){
            left: -30px;
           }
         }
         .owl-next {
           right: -60px;
           @media(max-width:768px){
            right: -30px;
           }
         }
      }
    }
    .primary-button{
      margin: 0 auto;
      display: flex;
    }
  }
}

.quote-section {
  padding: 150px 0;
  position: relative;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: 80px;
    left: -15px;
    width: 206px;
    height: 47px;
    background-image: url('../assets/images/left-coming-object.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .quote-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-basis: 100%;
    @media (max-width: $small-tablet) {
      flex-direction: column;
    }

    .image-wrapper {
      flex-basis: 50%;
      display: flex;
      justify-content: center;

      img {
        width: 90%;
        @media (max-width: $small-laptop) {
          width: 92%;
        }
      }

      @media (max-width: $small-tablet) {
        justify-content: center;
        flex-basis: 100%;
      }
    }

    .quote-wrapper {
      border-radius: 10px;
      padding: 1px;
      background: linear-gradient(to top, #78dfdf, #b2a3d2, #78dfdf, #b2a3d2) padding-box padding-box;
      border: 1px solid transparent;
      flex-basis: 55%;
      @media (max-width: $small-tablet) {
        flex-basis: 100%;
      }

      .quote-content {
        padding: 50px 30px 25px;
        border-radius: 10px;
        background-color: $white;
        position: relative;
        width: 380px;
        height: 630px;
        //height: 566px;

        .quote-text {
          position: relative;

          p {
            color: #000;
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            margin-bottom: 25px;
            position: relative;
          }

          h3 {
            color: #000;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            max-width: 400px;
            @media (max-width: $small-phone) {
              max-width: 100%;
            }
          }

          &:before {
            content: "\f10e";
            color: $quoteIcon2;
            position: absolute;
            top: -80px;
            left: 0px;
            font-size: 110px;
            font-family: var(--fa-style-family, "Font Awesome 6 Free");
            font-weight: var(--fa-style, 900);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }

        .quote-image {
          width: 130px;
          position: absolute;
          bottom: 30px;
          right: 30px;
          img {
            width: 100%;
            @media (max-width: $small-phone) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.overview-section-title {
  margin: 0 auto 140px !important;
}

.overview-section {
  padding: 80px 0;
  background-color: $pale-grey-bg;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .overview-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 70px;
    .overview-data{
      ul.overview-items-list{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 50px;
        }
        li{
          .overview-box {
            display: flex;
            gap: 32px;
            width: 295px;
            .overview-box-image {
              width: 60px;
              height: 60px;
              background-size: contain !important;
              background-repeat: no-repeat !important;
              background-position: center center !important;
              img{
                //width: 45px;
                //height: 45px;
              }
            }
              .content-wrapper{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 15px;
                flex-direction: column;
                width: 100%;
                cursor: default;
                h3{
                  font-size: 16px;
                  font-weight: 600;
                  color: #21b2bc;
                  text-transform: uppercase;
                  // &:hover{
                  //   //color: black;
                  //   //cursor: pointer;
                  // }
                }
                p{
                  font-size: 14px;
                  font-weight: 400;
                  color: #666666;
                }

            }
          }
        }
      }
    .overview-content{
      width: 60%;
      position: relative;
      img {
        max-width: 100%;
      }
      .overview-items {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        .item {
          cursor: pointer;
          position: absolute;
          clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 25% 100%);
          border-radius: 50px;
          &.one {
            right:155px;
            top: 55px;
            width: 195px;
            height: 145px;
            transform: rotate(20deg);
          }
          &.two {
            top: 160px;
            right: 45px;
            width: 165px;
            height: 140px;
            transform: rotate(63deg);
          }
          &.three {
            width: 160px;
            height: 140px;
            top: 320px;
            right: 25px;
            transform: rotate(100deg);
          }
          &.four {
            width: 185px;
            height: 145px;
            top: 450px;
            right: 90px;
            transform: rotate(138deg);
          }
          &.five {
            width: 200px;
            height: 145px;
            top: 512px;
            right: 240px;
            transform: rotate(180deg);
          }
          &.six {
            width: 200px;
            height: 141px;
            top: 460px;
            left: 88px;
            transform: rotate(220deg);
          }
          &.seven {
            width: 165px;
            height: 140px;
            top: 325px;
            left: 25px;
            transform: rotate(257deg);
          }
          &.eight {
            width: 184px;
            height: 140px;
            top: 165px;
            left: 35px;
            transform: rotate(293deg);
          }
          &.nine {
            width: 200px;
            height: 143px;
            top: 50px;
            left: 148px;
            transform: rotate(338deg);
          }
        }
        .desc{
          position: absolute;
          opacity: 0;
          width: 300px;
          transition: opacity 0.3s ease-in;
          p{
            color: $textBlack;
            font-size: 14px;
            font-weight: 600;
            word-break: break-word;
            margin: 0;
            position: absolute;
            width: calc(100% - 50px);
          }
          &:before{
            content: '';
            background-size: contain !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            display: block;
            height: 42px;
            width: 100%;
          }
          &.show{
            opacity: 1;
            transition: opacity 0.4s ease-out;
          }
          &.one{
            top: 35px;
            right: -40px;

            p{
              top: -20px;
              left: 45px;
            }
            &:before{
              background-image: url('../assets/images/tip1.svg');
            }
          }
          &.two{
            top: 170px;
            right: -200px;
            p{
              top: -20px;
              left: 45px;
            }
            &:before{
              background-image: url('../assets/images/tip1.svg');
            }
          }
          &.three{
            bottom: 254px;
            right: -224px;
            p{
              top: 0;
              left: 50px;
            }
            &:before{
              background-image: url('../assets/images/tip3.svg');
            }
          }
          &.four{
            bottom: 90px;
            right: -130px;
            p{
              top: 20px;
              left: 55px;
            }
            &:before{
              background-image: url('../assets/images/tip3.svg');
            }
          }
          &.five{
            bottom: 13px;
            right: 68px;
            p{
              top: 0;
              left: 50px;
            }
            &:before{
              background-image: url('../assets/images/tip3.svg');
            }
          }
          &.six{
            bottom: 85px;
            left: -130px;
            p{
              top: 0px;
              left: -30px;
              width: calc(100% - 20px);
            }
            &:before{
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              background-image: url('../assets/images/tip3.svg');
            }
          }
          &.seven{
            top: 395px;
            left: -227px;
            p{
              top: 0;
              left: 30px;
              width: calc(100% - 65px);
            }
            &:before{
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              background-image: url('../assets/images/tip3.svg');
            }
          }
          &.eight{
            top: 180px;
            left: -202px;
            p{
              top: -40px;
              left: 20px;
            };
            &:before{
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              background-image: url('../assets/images/tip1.svg');
            }
          }
          &.nine{
            top: 30px;
            left: -45px;
            p{
              top: -38px;
              left: 20px;
            }
            &:before{
              bottom: -45px;
              left: 0px;
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              background-image: url('../assets/images/tip1.svg');
            }
          }
        }
      }
      &.large{
        @media (max-width: $large-laptop) {
          display: none;
        }
      }
      &.small{
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 40px;
        width: 100%;
        .overview-image{
          width: 250px;
          height: 250px;
          margin: 0 auto;
          img{
            width: 100%;
          }
        }
        .overview-items-list{
          justify-content: center;
          align-items: center;
          gap: 15px;
          display: flex;
          flex-flow: wrap;
          width: 100%;
          li{
            width: calc(33.33% - 15px);
            height: 200px;
            @media (max-width: $large-phone) {
              width: calc(50% - 15px);
              height: 250px;
            }
            @media (max-width: $iphone-pro-max) {
              width: 100%;
              height: 300px;
            }
            .overview-box{
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 20px;
              width: 100%;
              height: 100%;
              background-color: $white;
              box-shadow: $teal-box-shadow;
              border-radius: 10px;
              flex-direction: column;
              padding: 15px;
              text-align: center;
              .image{
                width: 60px;
                height: 60px;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center center !important;
                &.image1{
                  background-image: url("../assets/images/w1-practice.svg");
                }
                &.image2{
                  background-image: url("../assets/images/w2-practice-billing.svg");
                }
                &.image3{
                  background-image: url("../assets/images/w3-patient-medical-profile.svg");
                }
                &.image4{
                  background-image: url("../assets/images/w4-ePrescription.svg");
                }
                &.image5{
                  background-image: url("../assets/images/w5-clinical.svg");
                }
                &.image6{
                  background-image: url("../assets/images/w6-patient.svg");
                }
                &.image7{
                  background-image: url("../assets/images/w7-specialty.svg");
                }
                &.image8{
                  background-image: url("../assets/images/w8-tele-medicine.svg");
                }
                &.image9{
                  background-image: url("../assets/images/w9-consent.svg");
                }
              }
              .content-wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex-direction: column;
                width: 100%;
                h3{
                  font-size: 16px;
                  font-weight: 500;
                  color: $aqua3;
                }
                p{
                  font-size: 14px;
                  font-weight: 400;
                  color: $textBlack;
                }
              }
            }
          }
        }
        @media (max-width: $large-laptop) {
          display: flex;
        }
      }
    }
  }
}

.hie-section{
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -30px;
    width: 189px;
    height: 268px;
    background-image: url('../assets/images/circle.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 80px;
    right: -25px;
    width: 206px;
    height: 47px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/right-coming-object.png');
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .hie-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (max-width: $small-tablet) {
      flex-direction: column;
    }
    .hie-item{
      width: 33.33%;
      text-align: center;
      height: 120px;
      justify-content: center;
      align-items: center;
      display: flex;
      gap: 10px;
      flex-direction: column;
      z-index: 1;
      position: relative;
      @media (max-width: $small-tablet) {
        height: 200px;
        width: 100%;
      }
      a{
        display: flex;
        justify-content: center;
        align-items: center;
        .hie-image{
          width: 200px;
          height: 120px;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-position: center center !important;
          transition: 0.5s all;
          filter: grayscale(100%);
          &.hie1{
            background-image: url("../assets/images/riayati.svg");
          }
          &.hie2{
            background-image: url("../assets/images/malaffi.svg");
          }
          &.hie3{
            background-image: url("../assets/images/nabidh.svg");
          }
        }
        &:hover{
          .hie-image{
            &.hie1, &.hie2, &.hie3{
              filter: grayscale(0%);
            }
          }
        }
      }
      &.hie-middle-item{
        border-width: 0 1px 0 1px;
        border-style: solid;
        border-color: $borderColor4;
        @media (max-width: $small-tablet) {
          border-width: 1px 0 1px 0;
        }
      }
    }
  }
}

.partner-section{
  padding: 80px 0;
  background-color: $pale-grey-bg;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  .partner-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (max-width: $small-tablet) {
      flex-direction: column;
    }
    .partner-item{
      width: 33.33%;
      text-align: center;
      height: 120px;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      gap: 10px;
      flex-direction: column;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background-color: #000;
        border-radius: 10px;
      }
      &:last-child{
        &:after {
          display: none;
        }
      }
      @media (max-width: $small-tablet) {
        height: 200px;
        width: 100%;
        justify-content: center;
        &:after {
          bottom: 0;
          left: 0;
          right: 0;
          top: auto;
          transition: auto;
          margin: 0 auto;
        }
      }
      a{
        height: 120px;
        align-items: center;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
      }
      img {
        width: 180px;
        height: 60px;
        object-fit: contain;
      }
      p {
        color: $textBlack;
        font-size: 16px;
      }
      &.partner-middle-item{
        //border-width: 0 1px 0 1px;
        //border-style: solid;
        //border-color: $borderColor3;
        @media (max-width: $small-tablet) {
          border-width: 1px 0 1px 0;
        }
      }
    }
  }
  &.home-partner {
    background-color: #f5f7fa;
    .partner-wrapper{
      .partner-item{
        justify-content: center;
      }
    }
  }
}


.clients-section{
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: calc(100% - 68%);
    left: -15px;
    width: 206px;
    height: 47px;
    background-image: url('../assets/images/left-coming-object.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 300px;
    right: -125px;
    transform: rotate(15deg);
    width: 250px;
    height: 158px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/title-balls.png');
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .section-title{
    margin: 0 auto 20px;
  }
  .marquee-wrapper{
    //overflow: hidden;
    width: 8500px;
    .marquee-content {
      display: flex;
      animation: scrolling 35s linear infinite running;
      align-items: center;
      //justify-content: flex-end;
      &:hover{
        animation-play-state: paused;
      }
      .marquee-item {
        margin: 5px 24px;
       img {
          max-width: 150px;
          max-height: 60px;
        }
      }
    }
  }
  .subscription-wrapper {
    text-align: center;
    margin-top: 32px;
    .subscription-content{
      margin-bottom: 42px;
      p {
        font-size: 20px;
        color: $textBlack;
        margin-bottom: 24px;
      }
    }
    .form-field{
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 60%;
      margin: 0 auto;
      position: relative;
      @media (max-width: $small-tablet) {
        max-width: 100%;
        //justify-content: flex-start;
      }
      input[type="text"] {
        display: flex;
        height: 50px;
        border-radius: 40px;
        text-align: center;
        border: 1px solid $borderColor5;
        outline: none;
        box-shadow: none;
        padding: 0 110px 0 20px;
        width: calc(100% - 200px);
        @media (max-width: $small-tablet) {
          width: calc(100% - 100px);
          padding: 0px 60px 0 20px;
        }
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-radius: 50px;
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
        background-image: linear-gradient(100deg,$topaz 0%, $bright-cyan 100%);
        transition: all ease 0.5s;
        color: $white;
        font-size: 18px;
        width: 200px;
        position: absolute;
        //right: 0;
        @media (max-width: $small-tablet) {
          width: 150px;
        }
          &:hover{
            background: transparent;
            color: #11adb3;
            border: 1px solid #11adb3;
        }
      }
    }
  }
  &.hie-clients{
    &:before{
      display: none;
    }
    &:after{
      left: -175px;
      width: 200px;
      height: 200px;
      opacity: .3;
      top: -80px;
      right: auto;
      transform: rotate(-15deg);
      @media (max-width: $small-tablet) {
        display: none;
      }
    }
  }
}

form.custom-captcha {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//Product Page Ends Here

@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translatex(-50%); }
}
@keyframes scrollltr {
  from {
      transform: translateX(-50%);
  }
  to {
      transform: translateX(0);
  }
}

#resultContact {
  &.contactModal {
    top: 278px;
  }
    z-index: 10;
    position: absolute;
    top: 294px;
    color: #e54852;
    text-align: center;
    //background: #e54852;
    //border-radius: 3px;
    //color: #fff !important;
    //font-weight: 400;
    //padding: 10px 15px;
    //z-index: 10;
    //text-align: center;
    //padding-left: 15px;
    //padding-right: 15px;
}
#resultDownload {
  &.contactModal {
    top: 278px;
  }
    z-index: 10;
    position: absolute;
    top: 294px;
    color: #e54852;
    text-align: center;
    //background: #e54852;
    //border-radius: 3px;
    //color: #fff !important;
    //font-weight: 400;
    //padding: 10px 15px;
    //z-index: 10;
    //text-align: center;
    //padding-left: 15px;
    //padding-right: 15px;
}
//  .pointer {
//    border-bottom: 10px solid #e54852;
//    border-left: 10px solid transparent;
//    border-right: 10px solid transparent;
//    height: 0;
//    left: 8px;
//    position: absolute;
//    top: -8px;
//    width: 0;
//  }
//}


.faq-section{
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-tablet) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -30px;
    width: 189px;
    height: 268px;
    background-image: url('../assets/images/circle.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 80px;
    right: -25px;
    width: 206px;
    height: 47px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/images/right-coming-object.png');
    @media (max-width: $small-tablet) {
      display: none;
    }
  }
  .faq-wrapper{
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 80px;
    display: flex;
    @media (max-width: $small-tablet) {
      flex-direction: column;
      gap: 20px;
    }
    .faq-item{
      flex-basis: 50%;
      .panel-group {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        .panel {
          margin-bottom: 0;
          border-radius: 6px;
          width: 100%;
        }
        .panel-group{
          margin-bottom: 0;
          border-radius: 6px;
          width: 100%;
        }
        .panel-default{
          .panel-heading {
            color: #000000;
            background-color: transparent;
            padding: 0;
            .panel-title {
              font-size: 16px;
                a{
                  padding: 10px 15px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 64px;
                  .iconWrapper {
                    width: 20px;
                    height: 20px;
                    background-color: #f5f7fa;
                    border-radius: 50px;
                    text-align: center;
                    i{
                      font-size: 12px;
                      color: #717171;
                      transition: 0.2s;
                    }
                }
              }
              a[aria-expanded="true"]
              .iconWrapper i {
                transition: 0.2s;
                transform: rotate(180deg);
              }

            }
          }
        }
      }
    }
    .faq-item-right{
      flex-basis: 50%;
    }
  }
}
.termsfeed-com---nb-simple{
  .cc-nb-buttons-container{
    @media(max-width:600px) {
      flex-wrap: wrap;
      gap: 15px;
      padding-bottom: 15px;
    }
  }
}
.sec-footprints{
  padding-top: 10px;
  overflow: hidden;
}
.sec-footprints-img{
  text-align: center;
  padding:70px 15px;
  img{
    max-width: 100%;
  }
}
.sec-keyfeatures{
  display: flex;
  justify-content: center;
  gap: 15px;
  .owl-carousel{
    position: relative;
    .owl-stage-outer{
      padding: 20px;
      .owl-item{
        width: auto !important;
        padding: 0 7px !important;
      }
    }
    .owl-nav.disabled{
      display: block;
      position: absolute;
      top: 40%;
      left:0;
      width: 100%;
      .owl-prev{
        float: left;
        background: #00000013;
        @media (min-width:1024px) {
          margin-left: 50px;
        }
      }
      .owl-next{
        float: right;
        background: #00000013;
      }
    }
  }
  .sec-feature-EXB{
    display: flex;
    width: 300px;
    height: 444px;
    padding:40px 20px;
    padding-bottom: 35px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.10);
    transition: all 0.3s;
    overflow: hidden;
    cursor: pointer;
    .feature-head {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      transition: all 0.3s;
      .sec-keyfeatures-icon{
        width: 128px;
        height: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sec-keyfeatures-disc{
      display: none;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
    }
    .sec-keyfeatures-title {
        height: 90px;
        margin-top: 60px;
        padding: 0 25px;
        transition: all 0.3s;
        h3{
          color: #274760;
          text-align: center; 
          font-size: 22px;
          font-weight: 500;
          line-height: 30px;
        }
    }
    &.expended{
      justify-content: flex-start;
      width: 397px;
      .feature-head {
        flex-direction: row;
        gap: 15px;
        .sec-keyfeatures-icon{
          flex: 73px 0 0;
          height: auto;
          width: 73px;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .sec-keyfeatures-title{
          padding: 0;
          margin: 0;
          height: auto;
          h3{
            text-align: left;
          }
        }
      }
      .sec-keyfeatures-disc{
          display: block;
          padding-left: 90px;
          opacity: 1;
          visibility: visible;
          p{
            color: #274760;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 25px;
            letter-spacing: -0.2px;
            text-align: left;
        }
      }
    }
  }
  
}
.se-features-section.sec-features{
  padding:180px 0 100px 0;
  ul{
    li{
      padding: 18px 10px 18px 36px !important;
      gap:17px !important;
    }
  }
}
.sec-nobg{
  &:after{
    display: none
  }
  &:before{
    bottom: 10%;
  }
  .subscription-wrapper{
    margin-top: 60px
  }
}
.se-main-banner.sec-banner{
  .banner-image{
    @media (max-width: $small-tablet){
      height: 40vh; 
    }
    .primary-button{
      margin-top: 250px !important;
      color: #fff;
      @media (max-width: $small-tablet){
        margin-top: 110px !important;  
      }
    }
    
    .banner-image-wrapper{
      @media (max-width: $small-tablet){
        height: 40vh; 
      }
      img{
        @media (max-width:1630px) {
          object-fit:cover;
          object-position: -130px 0;
        }
        @media (max-width: $medium-laptop){
          object-position: -135px 0;
        }
        @media (max-width: $small-tablet){
          object-position: -70px 0;
        }
      }
    }
  }
}
.pt-0{
  padding-top: 0 !important;
}
.collap-btn{
  .showless-text{
    display: none;
  }
  &.colleped{
    .showmore-text{
      display: none;
    }
    .showless-text{
      display: block
    }
  }
}
@import "media-queries";
