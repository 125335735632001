.service-heading-wrapper{
  padding: 52px 0 !important;
    .pr-heading1{
      color: #000;
      text-align: center;
      font-size: 4.5rem;
      font-weight: 600;
  }
}

.pr-background-image{
  .image-wrapper{
    position: absolute;
    right: -20%;
    top: -184px;
    //z-index: 999;
    width: 55%;
    opacity: 30%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.pr-wrapper {
  background: #f5f7fa;
  margin-bottom: 46px;
  //position: relative;
  //z-index: 1000;
  .pr-header{
    margin: 0;
    gap: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $small-tablet) {
      flex-direction: column-reverse;
      padding: 30px 0;
      gap: 30px;
    }

    h3 {
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 52px;
      color: black;
      @media (max-width: $small-tablet) {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
    .pr-title{
      display: flex;
      flex-direction: column;
      flex-basis: 45%;
      .pr-icons{
        display: flex;
        align-items: center;
        gap: 22px;
        .press-release{
          display: flex;
          align-items: center;
          gap: 8px;
          max-width: 320px;
          img{
            width: 24px;
            height: 24px;
          }
          p{
            font-size: 16px;
            color: #8f8f8f;
            font-weight: 400;
          }
        }
        .release-date{
          display: flex;
          align-items: center;
          gap: 8px;
          img{
            width: 24px;
            height: 24px;
          }
          p{
            font-size: 16px;
            color: #8f8f8f;
            font-weight: 400;
          }
        }
      }
    }
    .pr-image{
      flex-basis: 55%;
      height: 390px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

.pr-detail-wrapper{
  .pr-detail-content{
    .pr-detail{
      margin-bottom: 46px;
      p{
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
        a{
          text-decoration: none;
          color: #0fb4ba;
          &:hover{
            color: rgb(15 180 186 / 60%);
            transition: 0.5s;
          }
        }
        .cairo{
          font-weight: 600;
        }
        .image-caption{
          color: #44546A;
          font-size: 14px;
        }
      }
      ul{
        list-style: disc;
        padding: 0 25px 0 25px;
        li{
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 40px;
          &::marker{
            color: #0fb4ba;
          }
          a{
            text-decoration: none;
            color: #0fb4ba;
            &:hover{
              color: rgb(15 180 186 / 60%);
              transition: 0.5s;
            }
          }
        }
      }
      ol{
        padding: 0 25px 0 25px;
        li{
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 40px;
          padding-left: 10px;
          &::marker{
            color: #0fb4ba;
          }
          a{
            text-decoration: none;
            color: #0fb4ba;
            &:hover{
              color: rgb(15 180 186 / 60%);
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
}
